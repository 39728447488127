import background from "../img/light-blue-bg.jpg";
import { isMobile } from "react-device-detect";
import { BACKGROUND_COLOR } from "consts";

const appStyle = {
  container: {
    backgroundImage: `url(${background})`,
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
  },
  container2: {
    width: isMobile ? window.innerWidth : 400,
    display: "flex",
    flexDirection: "column",
    backgroundColor: BACKGROUND_COLOR,
    position: "relative",
  },
};

export default appStyle;
