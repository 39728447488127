import React from "react";
import { Input } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/form/FormItem";

const useStyles = makeStyles(styles);

function FormItem(props) {
  const classes = useStyles();
  const { placeholder, errorMessage, name, reset, value, disabled } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
        width: "100%",
      }}
    >
      <Input
        placeholder={placeholder || ""}
        name={name}
        className={classes.inputFormItem}
        onFocus={reset}
        defaultValue={value || ""}
        disabled={disabled}
      />
      <span className={classes.errorMessage}>{errorMessage || ""}</span>
    </div>
  );
}

export default FormItem;
