import { PRIMARY_COLOR } from "consts";

const headerStyle = {
  container: {
    width: "100%",
  },
  container1: {
    width: "100%",
    backgroundColor: PRIMARY_COLOR,
    padding: "10px 12px 8px 12px",
    fontWeight: "400",
    display: "flex",
    justifyContent: "space-between",
    fontSize: 22,
  },
  container2: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: PRIMARY_COLOR,
    paddingBottom: 4,
    paddingTop: 4,
  },
  container3: {
    color: "white",
    fontSize: 13,
    fontWeight: 600,
  },
  flags: {
    width: 30,
    height: 24,
    marginRight: 8,
    padding: "2px 4px 2px 4px",
    objectFit: "contain",
    borderRadius: 2,
  },
  title: {
    color: "white",
    fontWeight: 600,
    fontSize: 16,
  },
  icon: {
    color: "white",
    height: "100%",
    width: 20,
    overflow: "hidden",
    "&:hover": {
      opacity: 0.7,
    },
  },
};

export default headerStyle;
