import React, { useEffect, useState } from "react";
import styles from "assets/jss/rule";
import { makeStyles } from "@material-ui/core/styles";
import consts, { PRIMARY_COLOR } from "consts";
import Loading from "components/Loading/DotLoading";
import { useTranslation } from "react-i18next";
import utils from "common/utils";

const useStyles = makeStyles(styles);

const defaultRule = "<div></div>";

function Rule(props) {
  const classes = useStyles();
  const { getRule, rule, title } = props;
  const { i18n } = useTranslation("translation");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getRule(
      {
        isdn: utils.isdn() || "",
        gameCode: consts.GAME_CODE,
        language: i18n.language,
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <div className={classes.container}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <span style={{ color: PRIMARY_COLOR, fontSize: 13, fontWeight: 600 }}>
            {title}
          </span>
          <div
            className={classes.content}
            dangerouslySetInnerHTML={{ __html: `${rule || defaultRule}` }}
          />
        </>
      )}
    </div>
  );
}

export default Rule;
