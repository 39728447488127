import React from "react";
import styles from "assets/jss/predict/match";
import { makeStyles } from "@material-ui/core/styles";
import CardPredict from "../Card/CardPredict";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import _ from "lodash";
const useStyles = makeStyles(styles);
import consts from "../../consts";
import Loading from "../Loading/DotLoading";
import utils from "../../common/utils";

function MatchComponent(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation("translation");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    console.log(props.tab);
    props.getPredictSession(
      {
        isdn: "",
        leagueId: props.leagueId || "1",
        gameCode: consts.GAME_CODE,
        language: i18n.language,
        pageNumber: 1,
        predictionType: 1,
        pageSize: consts.PAGE_SIZE,
      },
      () => {
        setLoading(false);
      }
    );
  }, [props.tab]);

  return (
    <div className={classes.container}>
      {_.map(props.session.lstSession || [], (e) => (
        <CardPredict key={e.id}>
          <div className={classes.matchContainer}>
            <span className={classes.matchDesc}>{e.matchDesc}</span>
            <div className={classes.flags}>
              <div className={classes.flag}>
                <img src={utils.getImageUrl(e.firstTeamImageUrl)} />
                <span>{e.firstTeamName}</span>
              </div>
              <span>vs</span>
              <div className={classes.flag}>
                <img src={utils.getImageUrl(e.secondTeamImageUrl)} />
                <span>{e.secondTeamName}</span>
              </div>
            </div>
            <div className={classes.time}>
              {utils.formatDateMatch(e.predictionStartTime)}
              <span>{e.matchPlace}</span>
            </div>
          </div>
          <Button
            className={classes.btnAuction}
            onClick={() => {
              props.predict(e);
            }}
          >
            {t("predictBtnV2")}{" "}
          </Button>
        </CardPredict>
      ))}
      {loading ? <Loading /> : <div />}
    </div>
  );
}

export default MatchComponent;
