import { PRIMARY_COLOR } from "consts";
import { isMobile } from "react-device-detect";

const navigationStyle = {
  container: {
    width: isMobile ? "100%" : 400,
    display: "flex",
    alignItems: "center",
    justifyContent: " space-evenly",
    backgroundColor: "white",
    boxShadow: "0px -4px 8px rgba(50, 50, 50, 0.1)",
    paddingTop: 8,
    paddingBottom: 8,
    "& :hover": {
      opacity: 0.7,
    },
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    zIndex: 1000,
  },
  item: {
    flex: 1,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(0,0,0,0.3)",
  },
  image: {
    maxWidth: 30,
    maxHeight: 30,
    "& path": {
      fill: "rgba(0,0,0,0.3)",
    },
  },
  imageIconActive: {
    maxWidth: 30,
    maxHeight: 30,
    "& path": {
      fill: `${PRIMARY_COLOR} !important`,
    },
  },
  iconActions: {
    fontSize: 22,
  },
  iconActionActive: {
    fontSize: 22,
    color: PRIMARY_COLOR,
  },
  title: {
    fontSize: 13,
  },
  titleActive: {
    fontSize: 13,
    color: PRIMARY_COLOR,
  },
};

export default navigationStyle;
