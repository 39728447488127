import React from "react";
import "assets/css/dot.css";

function Loading() {
  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        // alignItems: "center",
        marginTop: 50,
        width: "100%",
        backgroundColor: "rgba(0,0,0,0.05)",
      }}
    >
      <div className={"container-dot"}>
        <span className="dots-cont">
          <span className="dot dot-1"></span>
          <span className="dot dot-2"></span>
          <span className="dot dot-3"></span>
        </span>
      </div>
    </div>
  );
}

export default Loading;
