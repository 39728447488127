import HomeRequest from "./HomeRequest";
import AuthRequest from "./AuthRequest";
import HelpRequest from "./HelpRequest";
import ResultRequest from "./ResultRequest";
import PredictRequest from "./PredictRequest";

const requestMap = {
  HomeRequest,
  AuthRequest,
  HelpRequest,
  ResultRequest,
  PredictRequest,
};

const instances = {};

export default class RequestFactory {
  static getRequest(classname) {
    const RequestClass = requestMap[classname];
    if (!RequestClass) {
      throw new Error(`Invalid request class name: ${classname}`);
    }

    let requestInstance = instances[classname];
    if (!requestInstance) {
      requestInstance = new RequestClass();
      instances[classname] = requestInstance;
    }
    return requestInstance;
  }
}
