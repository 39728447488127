import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import styles from "assets/jss/modal";
import consts, { PRIMARY_COLOR } from "consts";
import { makeStyles } from "@material-ui/core/styles";
import "assets/css/modal_repair.css";
import { useTranslation } from "react-i18next";
import FormItem from "./FormItem";
import utils from "common/utils";
import { isMobile } from "react-device-detect";
import OtpItem from "./OtpItem";

const useStyles = makeStyles(styles);

function ModalLogin(props) {
  const classes = useStyles();
  const { visible, handleCancel, handleOk } = props;
  const [loading] = useState(false);
  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const { t, i18n } = useTranslation("translation");
  const [otpError, setOTPError] = useState("");
  const [otpShow, setOtpShow] = useState(false);

  const sendLogin = (phone, otp) => {
    props.login(
      {
        isdn: phone,
        gameCode: consts.GAME_CODE,
        otp,
        language: i18n.language,
      },
      () => {
        utils.showNotification(t("loginBtn"), t("login-notification-success"));
        props.cancelModalLogin();
        handleOk();
      }
    );
  };

  const onFinish = (event) => {
    event.preventDefault();
    const { phoneInput, otpInput } = event.target;
    console.log(event.target, "event.target");
    if (phoneInput) {
      if (phoneInput.value == "") setErrorPhone(t("loginPhonePlaceholder"));
      if (!utils.checkPhoneNumber(phoneInput.value)) {
        setErrorPhone(t("phoneFormatError"));
        return;
      }
      setPhone(phoneInput.value);
      if (isMobile) getOtpFromSMS();
      props.sendOtp(
        {
          isdn: phoneInput.value,
          gameCode: consts.GAME_CODE,
          language: i18n.language,
        },
        () => {
          setOtpShow(true);
        }
      );
    } else if (otpInput) {
      if (otpInput.value == "") {
        setErrorPhone("Please input your otp!");
        return;
      }
      sendLogin(phone, otpInput.value);
    }
  };

  const getOtpFromSMS = () => {
    if (!window.OTPCredential) {
      console.log("browser not support");
      return;
    }
    window.addEventListener("DOMContentLoaded", () => {
      const abort = new AbortController();

      setTimeout(() => {
        // abort after two minutes
        abort.abort();
      }, 2 * 60 * 1000);
      navigator.credentials
        .get({
          signal: abort.signal,
          otp: {
            transport: ["sms"],
          },
        })
        .then(({ code }) => {
          console.log(code, "code");
          if (code) {
            // setDefaultValue(code);
          }
          abort.abort();
        });
    });
  };

  useEffect(() => {
    return () => {
      setPhone("");
      setOtpShow(false);
    };
  }, []);

  return (
    <>
      <Modal
        title=""
        destroyOnClose={true}
        visible={visible}
        onOk={sendLogin}
        onCancel={handleCancel}
        width={320}
        closable={false}
        bodyStyle={{ borderRadius: 10 }}
        footer={[
          <form
            name="login"
            onSubmit={onFinish}
            className={classes.formItem}
            key={"form-login"}
          >
            {otpShow ? (
              <OtpItem
                label={"Otp"}
                require={true}
                placeholder={t("otpPlaceholder")}
                errorMessage={otpError}
                name={"otpInput"}
                reset={() => setOTPError("")}
                autoComplete={"one-time-code"}
                reSend={() => {
                  if (isMobile) getOtpFromSMS();
                  props.sendOtp(
                    {
                      isdn: phone,
                      gameCode: consts.GAME_CODE,
                      language: i18n.language,
                    },
                    () => {}
                  );
                }}
              />
            ) : (
              <FormItem
                label={"Phone"}
                require={true}
                placeholder={t("loginPhonePlaceholder")}
                errorMessage={errorPhone}
                name={"phoneInput"}
                reset={() => setErrorPhone("")}
              />
            )}
            <Button
              style={{ backgroundColor: PRIMARY_COLOR }}
              className={classes.btnModal}
              type={"submit"}
              loading={loading}
              htmlType="submit"
            >
              {otpShow ? t("loginBtn") : t("receiveOtp")}
            </Button>
          </form>,
        ]}
      >
        <div style={{ textAlign: "center" }}>
          <span
            style={{
              fontSize: 13,
              lineHeight: "18px",
              color: "#181725",
              fontWeight: 600,
            }}
          >
            {t("loginTitle")}
          </span>
        </div>
      </Modal>
    </>
  );
}

export default ModalLogin;
