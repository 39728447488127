const modalAcceptAuctionStyle = {
  btnModal: {
    borderRadius: 20,
    border: "none",
    fontSize: 13,
    fontWeight: 700,
    lineHeight: "20px",
    height: 28,
    color: "white",
  },
  formItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default modalAcceptAuctionStyle;
