import { connect } from "react-redux";
import Predict from "../views/Predict";
import common from "redux/actions/common";
import actions from "redux/actions/predict";

const mapStateToProps = (state) => ({
  subPage: state.common.subPage,
  session: state.predict.session,
  history: state.predict.history,
  sessionChampion: state.predict.sessionChampion,
  sessionGoal: state.predict.sessionGoal,
  teams: state.predict.teams,
  player: state.predict.player,
  prize: state.predict.prize,
  tab: state.predict.tab,
  leagues: state.predict.leagues,
  clubs: state.predict.clubs,
});

const mapDispatchToProps = (dispatch) => ({
  setSubPage: (subPage) => {
    dispatch(common.setSubPage(subPage));
  },
  getPredictSession: (params, callback) => {
    dispatch(actions.getPredictSession(params, callback));
  },
  getPlayHistory: (params, callback) => {
    dispatch(actions.getPlayHistory(params, callback));
  },
  getListTeam: (params, callback) => {
    dispatch(actions.getListTeam(params, callback));
  },
  getListPlayer: (params, callback) => {
    dispatch(actions.getListPlayer(params, callback));
  },
  predict: (params, callback, fail) => {
    dispatch(actions.predict(params, callback, fail));
  },
  onAddTurn: (params, callback) => {
    dispatch(actions.addTurn(params, callback));
  },
  registerService: (params, callback) => {
    dispatch(actions.registerService(params, callback));
  },
  getListPrize: (params, callback) => {
    dispatch(actions.getListPrize(params, callback));
  },
  validateTurn: (params, callback) => {
    dispatch(actions.validateTurn(params, callback));
  },
  getFootballLeagues: (param, callback) => {
    dispatch(actions.getFootballLeague(param, callback));
  },
  getClubs: (param, callback) => {
    dispatch(actions.getClub(param, callback));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Predict);
