import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import rf from "../../requests/RequestFactory";
import actions from "../actions/predict";
import _ from "lodash";
import {
  ADD_TURN,
  GET_LIST_PLAYER,
  GET_LIST_PRIZE,
  GET_LIST_TEAM,
  GET_PLAY_HISTORY,
  GET_PREDICT_SESSION,
  PREDICT,
  REGISTER_SERVICE,
  VALIDATE_TURN,
  GET_FOOTBALL_LEAGUE,
  GET_CLUB,
} from "../actions/predict/action_types";
import utils from "../../common/utils";

function* getPredictSession(action) {
  console.log("=== getPredictSession ===", action);
  try {
    const { data, status } = yield call(
      (params) => rf.getRequest("PredictRequest").getPredictSession(params),
      action.params
    );
    if (_.isFunction(action.callback)) action.callback();

    if (!status) {
      yield put(actions.getPredictSessionFailed(new Error("Error Step 1")));
      return;
    }
    console.log(data, "data");
    yield put(
      actions.getPredictSessionSucceed(
        data.result.wsResponse,
        action.params.predictionType
      )
    );
  } catch (err) {
    yield put(actions.getPredictSessionFailed(err));
    if (_.isFunction(action.callback)) action.callback();
  }
}

function* getListTeam(action) {
  console.log("=== getListTeam ===", action);
  try {
    const { data, status } = yield call(
      (params) => rf.getRequest("PredictRequest").getListTeam(params),
      action.params
    );
    if (_.isFunction(action.callback)) action.callback();

    if (!status) {
      yield put(actions.getListTeamFailed(new Error("Error Step 1")));
      return;
    }
    yield put(actions.getListTeamSucceed(data.result.wsResponse));
  } catch (err) {
    yield put(actions.getListTeamFailed(err));
  }
}

function* getFootballLeague(action) {
  console.log("=== getFootballLeague ===", action);
  try {
    const { data, status } = yield call(
      (params) => rf.getRequest("PredictRequest").getFootballLeague(params),
      action.params
    );
    if (_.isFunction(action.callback)) action.callback();

    if (!status) {
      yield put(actions.getFootballLeagueFailed(new Error("Error Step 1")));
      return;
    }
    yield put(actions.getFootballLeagueSucceed(data.result.wsResponse));
  } catch (err) {
    yield put(actions.getFootballLeagueFailed(err));
  }
}

function* getListPlayer(action) {
  console.log("=== getListPlayer ===", action);
  try {
    const { data, status } = yield call(
      (params) => rf.getRequest("PredictRequest").getListPlayer(params),
      action.params
    );
    if (_.isFunction(action.callback)) action.callback();

    if (!status) {
      yield put(actions.getListPlayerFailed(new Error("Error Step 1")));
      return;
    }
    yield put(actions.getListPlayerSucceed(data.result.wsResponse));
  } catch (err) {
    yield put(actions.getListPlayerFailed(err));
  }
}

function* predict(action) {
  console.log("=== predict ===", action);
  try {
    const { data, status } = yield call(
      (params) => rf.getRequest("PredictRequest").predict(params),
      action.params
    );
    if (_.isFunction(action.callback)) action.callback();

    console.log(data, "predict");
    if (
      !status ||
      (data.result.wsResponse.playType &&
        data.result.wsResponse.playType == "ADDTURN")
    ) {
      yield put(actions.predictFailed(new Error("Error Step 1")));
      if (_.isFunction(action.fail)) action.fail(data.result);
      return;
    }
    utils.showNotification("", data.result.message);
    yield put(actions.predictSucceed(data.result.wsResponse));
  } catch (err) {
    yield put(actions.predictFailed(err));
  }
}

function* getPlayHistory(action) {
  console.log("=== getPlayHistory ===", action);
  try {
    const { data, status } = yield call(
      (params) => rf.getRequest("PredictRequest").getPlayHistory(params),
      action.params
    );
    if (_.isFunction(action.callback)) action.callback();

    if (!status) {
      yield put(actions.getPlayHistoryFailed(new Error("Error Step 1")));
      return;
    }
    yield put(actions.getPlayHistorySucceed(data.result.wsResponse));
  } catch (err) {
    yield put(actions.getPlayHistoryFailed(err));
  }
}

function* registerService(action) {
  console.log("=== registerService ===", action);
  try {
    const { data, status } = yield call(
      (params) => rf.getRequest("PredictRequest").registerService(params),
      action.params
    );
    if (_.isFunction(action.callback)) action.callback();

    if (!status) {
      yield put(actions.getPlayHistoryFailed(new Error("Error Step 1")));
      return;
    }
    yield put(actions.registerServiceSucceed());
    if (_.isFunction(action.callback)) action.callback(data.result);
  } catch (err) {
    yield put(actions.registerServiceFailed(err));
    if (_.isFunction(action.callback)) action.callback();
  }
}

function* addTurn(action) {
  console.log("=== addTurn ===", action);
  try {
    const { data, status } = yield call(
      (params) => rf.getRequest("PredictRequest").addTurn(params),
      action.params
    );
    if (_.isFunction(action.callback)) action.callback();

    if (!status) {
      yield put(actions.getPlayHistoryFailed(new Error("Error Step 1")));
      return;
    }
    yield put(actions.addTurnSucceed());
    if (_.isFunction(action.callback)) action.callback(data.result);
  } catch (err) {
    yield put(actions.addTurnFailed(err));
    if (_.isFunction(action.callback)) action.callback();
  }
}

function* getListPrize(action) {
  console.log("=== getListPrize ===", action);
  try {
    const { data, status } = yield call(
      (params) => rf.getRequest("PredictRequest").getListPrize(params),
      action.params
    );
    if (_.isFunction(action.callback)) action.callback();

    if (!status) {
      yield put(actions.getListPrizeFailed(new Error("Error Step 1")));
      return;
    }
    yield put(actions.getListPrizeSucceed(data.result.wsResponse));
  } catch (err) {
    yield put(actions.getListPrizeFailed(err));
    if (_.isFunction(action.callback)) action.callback();
  }
}

function* getClub(action) {
  console.log("=== getClub ===", action);
  try {
    const { data, status } = yield call(
      (params) => rf.getRequest("PredictRequest").getClubFootball(params),
      action.params
    );
    if (_.isFunction(action.callback)) action.callback();

    if (!status) {
      yield put(actions.getClubFailed(new Error("Error Step 1")));
      return;
    }
    yield put(actions.getClubSucceed(data.result.wsResponse));
  } catch (err) {
    yield put(actions.getClubFailed(err));
    if (_.isFunction(action.callback)) action.callback();
  }
}

function* validateTurn(action) {
  console.log("=== validateTurn ===", action);
  try {
    const { data, status } = yield call(
      (params) => rf.getRequest("PredictRequest").validateTurn(params),
      action.params
    );
    if (_.isFunction(action.callback)) action.callback(null);

    if (!status) {
      yield put(actions.validateTurnFailed(new Error("Error Step 1")));
      return;
    }
    yield put(actions.validateTurnSucceed());
    if (_.isFunction(action.callback))
      action.callback(data.result.wsResponse.isRemainFree);
  } catch (err) {
    yield put(actions.validateTurnFailed(err));
    if (_.isFunction(action.callback)) action.callback(null);
  }
}

function* watchAllPredict() {
  yield takeLatest(GET_PREDICT_SESSION, getPredictSession);
  yield takeLatest(GET_LIST_TEAM, getListTeam);
  yield takeLatest(GET_LIST_PLAYER, getListPlayer);
  yield takeLatest(PREDICT, predict);
  yield takeLatest(GET_PLAY_HISTORY, getPlayHistory);
  yield takeLatest(REGISTER_SERVICE, registerService);
  yield takeLatest(ADD_TURN, addTurn);
  yield takeLatest(GET_LIST_PRIZE, getListPrize);
  yield takeLatest(VALIDATE_TURN, validateTurn);
  yield takeLatest(GET_FOOTBALL_LEAGUE, getFootballLeague);
  yield takeLatest(GET_CLUB, getClub);
}

export default function* rootSaga() {
  yield all([fork(watchAllPredict)]);
}
