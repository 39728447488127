import React, { useState } from "react";
import { Button, Modal } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/modal";
import "assets/css/modal_repair.css";
import { useTranslation } from "react-i18next";
import { PRIMARY_COLOR } from "consts";

const useStyles = makeStyles(styles);

function RegisterServiceModal(props) {
  const classes = useStyles();
  const { visible, handleOk, handleCancel } = props;
  const [loading] = useState(false);
  const { t } = useTranslation("translation");

  return (
    <Modal
      title=""
      destroyOnClose={true}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={320}
      closable={false}
      bodyStyle={{ borderRadius: 10 }}
      footer={[
        <div
          style={{ display: "flex", justifyContent: "space-evenly" }}
          key={"registerDiv"}
        >
          <Button
            key="back"
            onClick={handleCancel}
            style={{
              backgroundColor: "white",
              border: `1.5px solid ${PRIMARY_COLOR}`,
              color: PRIMARY_COLOR,
              lineHeight: "18px",
            }}
            className={classes.btnModal}
          >
            {t("cancelBtn")}
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
            style={{ backgroundColor: PRIMARY_COLOR }}
            className={classes.btnModal}
          >
            {t("registerBtn")}
          </Button>
        </div>,
      ]}
    >
      <div style={{ textAlign: "center" }}>
        <span
          style={{
            fontSize: 13,
            lineHeight: "18px",
            color: "#181725",
            fontWeight: 600,
          }}
        >
          {t("signUpToContinue")}
        </span>
      </div>
    </Modal>
  );
}

export default RegisterServiceModal;
