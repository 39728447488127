export const BASE_URL = process.env.REACT_APP_SERVER_URL;
export const DNS_IMAGE = process.env.REACT_APP_DNS_IMAGE;
export const TIME_OF_DEBOUNCE = 300;
export const PRIMARY_COLOR = "#FD7E14";
export const BACKGROUND_COLOR = "white";
export const SECOND_COLOR = "#E6E6FA";
export const CURRENCY_UNIT = "MT";
export const DATA_UNIT = "MB";

const CONTROLS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export default {
  // ============ RBAC ============
  // todo define all controls in screen here...
  CONTROLS,
  EN: "en",
  PT: "pt",
  TYPE_ERROR: "error",
  PAGE_SIZE: 10,
  GAME_CODE: process.env.REACT_APP_GAME_CODE,
};
