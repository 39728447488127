import {
  CANCEL_MODAL_LOGIN,
  LOGIN_SUCCEED,
  LOGOUT,
  LOGOUT_SUCCEED,
  OPEN_MODAL_LOGIN,
} from "../actions/auth/action_types";
import { BASE_URL } from "../../consts";
import axios from "axios";
import _ from "lodash";

const defaultParams = {
  openModalLogin: false,
  isLogout: false,
};

export default (state = defaultParams, action) => {
  switch (action.type) {
    case LOGIN_SUCCEED:
      localStorage.setItem("token", action.token);
      localStorage.setItem("isdn", action.isdn);
      return {
        ...state,
        openModalLogin: false,
      };
    case OPEN_MODAL_LOGIN:
      return {
        ...state,
        openModalLogin: true,
      };
    case CANCEL_MODAL_LOGIN:
      window.location.replace("/");

      _.debounce(() => {
        return {
          ...state,
          openModalLogin: false,
        };
      }, 100);
      break;

    case LOGOUT:
      return {
        ...state,
        isLogout: true,
      };
    case LOGOUT_SUCCEED:
      localStorage.removeItem("token");
      localStorage.removeItem("isdn");
      window.axios = axios.create({
        baseURL: BASE_URL,
      });
      return {
        ...state,
        isLogout: false,
      };
    default:
      return {
        ...state,
      };
  }
};
