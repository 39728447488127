import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import rf from "../../requests/RequestFactory";
import actions from "../actions/auth";
import _ from "lodash";
import { GET_OTP, LOGIN } from "../actions/auth/action_types";

function* login(action) {
  console.log("=== login ===", action);
  try {
    const { data, status } = yield call(
      (params) => rf.getRequest("AuthRequest").login(params),
      action.params
    );
    console.log(data, "data login");

    if (!status) {
      yield put(actions.loginFailed(new Error("Error Step 1")));
      return;
    }
    yield put(actions.loginSucceed(data.result.message, action.params.isdn));
    if (_.isFunction(action.callback)) action.callback();
  } catch (err) {
    yield put(actions.loginFailed(err));
  }
}

function* getOtp(action) {
  console.log("=== getOtp ===", action);
  try {
    const { data, status } = yield call(
      (params) => rf.getRequest("AuthRequest").getOtp(params),
      action.params
    );
    console.log(data, "data getOtp");

    if (!status) {
      yield put(actions.getOtpFailed(new Error("Error Step 1")));
      return;
    }
    yield put(actions.getOtpSucceed(data.result.message));
    if (_.isFunction(action.callback)) action.callback();
  } catch (err) {
    yield put(actions.getOtpFailed(err));
  }
}

function* watchAllAuth() {
  yield takeLatest(LOGIN, login);
  yield takeLatest(GET_OTP, getOtp);
}

export default function* rootSaga() {
  yield all([fork(watchAllAuth)]);
}
