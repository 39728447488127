import BaseRequest from "./BaseRequest";

export default class HomeRequest extends BaseRequest {
  url = "";
  /**
   * @param {string} params.isdn
   * @param {string} params.otp
   * @param {string} params.gameCode
   * @param {string} params.language
   * @returns {Promise<BaseRequest._responseHandler.props.data|undefined>}
   */
  login(params) {
    let data = {
      isWrap: "1",
      wsCode: "wsLoginOtpBola",
      wsRequest: params,
    };
    return this.post(this.url, data);
  }

  /**
   * @param {string} params.isdn
   * @param {string} params.gameCode
   * @param {string} params.language
   * @returns {Promise<BaseRequest._responseHandler.props.data|undefined>}
   */
  getOtp(params) {
    let data = {
      isWrap: "1",
      wsCode: "wsGetOtpBola",
      wsRequest: params,
    };
    return this.post(this.url, data);
  }
}
