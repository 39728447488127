import {
  GET_LIST_PLAYER_SUCCEED,
  GET_LIST_PRIZE_SUCCEED,
  GET_LIST_TEAM_SUCCEED,
  GET_PLAY_HISTORY_SUCCEED,
  GET_PREDICT_SESSION_SUCCEED,
  GOTO_TAB,
  GET_FOOTBALL_LEAGUE_SUCCEED,
  GET_CLUB_SUCCEED,
} from "../actions/predict/action_types";
import _ from "lodash";

const defaultParams = {
  session: {},
  sessionChampion: [],
  sessionGoal: [],
  history: [],
  teams: [],
  player: [],
  prize: [],
  tab: "1",
  leagues: [],
  clubs: [],
};

export default (state = defaultParams, action) => {
  switch (action.type) {
    case GET_PREDICT_SESSION_SUCCEED:
      switch (action.typePredict) {
        case 1:
          return {
            ...state,
            session: _.find(action.session, (e) => e.id == 1),
          };
        case 2:
          console.log(state.session, "state.session");
          return {
            ...state,
            sessionChampion: _.find(action.session, (e) => e.id == 2)
              .lstSession[0],
          };
        case 3:
          return {
            ...state,
            sessionGoal: _.find(action.session, (e) => e.id == 3).lstSession[0],
          };
        default:
          return {
            ...state,
          };
      }
    case GET_PLAY_HISTORY_SUCCEED:
      return {
        ...state,
        history: action.data,
      };
    case GET_LIST_TEAM_SUCCEED:
      return {
        ...state,
        teams: action.data,
      };
    case GET_LIST_PLAYER_SUCCEED:
      return {
        ...state,
        player: action.data,
      };
    case GOTO_TAB:
      return {
        ...state,
        tab: action.tab,
      };
    case GET_LIST_PRIZE_SUCCEED:
      return {
        ...state,
        prize: action.data,
      };
    case GET_FOOTBALL_LEAGUE_SUCCEED:
      return {
        ...state,
        leagues: action.data,
      };
    case GET_CLUB_SUCCEED:
      return {
        ...state,
        clubs: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};
