export const LOGIN = "LOGIN";
export const LOGIN_SUCCEED = "LOGIN_SUCCEED";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const GET_OTP = "GET_OTP";
export const GET_OTP_SUCCEED = "GET_OTP_SUCCEED";
export const GET_OTP_FAILED = "GET_OTP_FAILED";

export const OPEN_MODAL_LOGIN = "OPEN_MODAL_LOGIN";
export const OPEN_MODAL_LOGIN_SUCCEED = "OPEN_MODAL_LOGIN_SUCCEED";
export const OPEN_MODAL_LOGIN_FAILED = "OPEN_MODAL_LOGIN_FAILED";

export const CANCEL_MODAL_LOGIN = "CANCEL_MODAL_LOGIN";
export const CANCEL_MODAL_LOGIN_SUCCEED = "CANCEL_MODAL_LOGIN_SUCCEED";
export const CANCEL_MODAL_LOGIN_FAILED = "CANCEL_MODAL_LOGIN_FAILED";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCEED = "LOGOUT_SUCCEED";
export const LOGOUT_FAILED = "LOGOUT_FAILED";
