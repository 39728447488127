import {
  GET_GUIDE_SUCCEED,
  GET_RULE_SUCCEED,
} from "../actions/help/action_types";

const defaultParams = {
  rule: "",
  guide: "",
};

export default (state = defaultParams, action) => {
  switch (action.type) {
    case GET_GUIDE_SUCCEED:
      return {
        ...state,
        guide: action.data,
      };
    case GET_RULE_SUCCEED:
      return {
        ...state,
        rule: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};
