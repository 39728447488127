import { isSafari } from "react-device-detect";
import { PRIMARY_COLOR } from "../../../consts";

const matchStyles = {
  container: {
    overflowY: isSafari ? "scroll" : "auto",
  },
  btnAuction: {
    fontSize: window.innerWidth < 350 ? 10 : 13,
    backgroundColor: PRIMARY_COLOR,
    borderRadius: 5,
    color: "white",
    border: "none",
    padding: "8px 15px 6px 15px",
    height: "min-content",
    "&:hover": {
      backgroundColor: "white",
      border: `1px solid ${PRIMARY_COLOR}`,
      color: PRIMARY_COLOR,
    },
    "&:focus": {
      backgroundColor: "white",
      border: `1px solid ${PRIMARY_COLOR}`,
      color: PRIMARY_COLOR,
    },
    "& span": {
      fontWeight: 600,
      letterSpacing: 0.1,
      textTransform: "uppercase",
      lineHeight: "18px",
    },
  },
  matchContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  flags: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 8,
    "& span": {
      margin: `0px ${window.innerWidth < 350 ? "0" : "10px"}`,
      color: PRIMARY_COLOR,
      fontSize: window.innerWidth < 350 ? 13 : 15,
      lineHeight: "16px",
    },
  },
  flag: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 80,
    "& img": {
      width: 40,
      objectFit: "contain",
      boxShadow: "0px 1px 5px rgba(0,0,0,0.2)",
      borderRadius: 20,
    },
    "& span": {
      fontSize: window.innerWidth < 350 ? 11 : 13,
      fontWeight: 600,
      lineHeight: "18px",
      fontFamily: "Montserrat",
      color: "black",
      margin: 0,
      marginTop: 12,
      textAlign: "center",
    },
  },
  time: {
    fontSize: 10,
    fontFamily: "Montserrat",
    lineHeight: "18px",
    letterSpacing: "0.1px",
    color: PRIMARY_COLOR,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  matchDesc: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "18px",
    color: PRIMARY_COLOR,
    fontFamily: "Montserrat",
  },
};

export default matchStyles;
