import {
  GET_PREDICT_SESSION,
  GET_PREDICT_SESSION_SUCCEED,
  GET_PREDICT_SESSION_FAILED,
  GET_LIST_TEAM,
  GET_LIST_TEAM_SUCCEED,
  GET_LIST_TEAM_FAILED,
  GET_LIST_PLAYER,
  GET_LIST_PLAYER_SUCCEED,
  GET_LIST_PLAYER_FAILED,
  PREDICT,
  PREDICT_SUCCEED,
  PREDICT_FAILED,
  GET_PLAY_HISTORY,
  GET_PLAY_HISTORY_SUCCEED,
  GET_PLAY_HISTORY_FAILED,
  GOTO_TAB,
  ADD_TURN,
  ADD_TURN_SUCCEED,
  ADD_TURN_FAILED,
  REGISTER_SERVICE,
  REGISTER_SERVICE_SUCCEED,
  REGISTER_SERVICE_FAILED,
  GET_LIST_PRIZE,
  GET_LIST_PRIZE_SUCCEED,
  GET_LIST_PRIZE_FAILED,
  VALIDATE_TURN,
  VALIDATE_TURN_SUCCEED,
  VALIDATE_TURN_FAILED,
  GET_FOOTBALL_LEAGUE,
  GET_FOOTBALL_LEAGUE_FAILED,
  GET_FOOTBALL_LEAGUE_SUCCEED,
  GET_CLUB,
  GET_CLUB_FAILED,
  GET_CLUB_SUCCEED,
} from "./action_types";

export default {
  getPredictSession: (params, callback) => ({
    type: GET_PREDICT_SESSION,
    params,
    callback,
  }),
  getPredictSessionSucceed: (session, typePredict) => ({
    type: GET_PREDICT_SESSION_SUCCEED,
    session,
    typePredict: typePredict || 1,
  }),
  getPredictSessionFailed: (err) => ({
    type: GET_PREDICT_SESSION_FAILED,
    err,
  }),

  getListTeam: (params, callback) => ({
    type: GET_LIST_TEAM,
    params,
    callback,
  }),
  getListTeamSucceed: (data) => ({
    type: GET_LIST_TEAM_SUCCEED,
    data,
  }),
  getListTeamFailed: (err) => ({
    type: GET_LIST_TEAM_FAILED,
    err,
  }),

  getFootballLeague: (params, callback) => ({
    type: GET_FOOTBALL_LEAGUE,
    params,
    callback,
  }),
  getFootballLeagueSucceed: (data) => ({
    type: GET_FOOTBALL_LEAGUE_SUCCEED,
    data,
  }),
  getFootballLeagueFailed: (err) => ({
    type: GET_FOOTBALL_LEAGUE_FAILED,
    err,
  }),

  getClub: (params, callback) => ({
    type: GET_CLUB,
    params,
    callback,
  }),
  getClubSucceed: (data) => ({
    type: GET_CLUB_SUCCEED,
    data,
  }),
  getClubFailed: (err) => ({
    type: GET_CLUB_FAILED,
    err,
  }),

  getListPlayer: (params, callback) => ({
    type: GET_LIST_PLAYER,
    params,
    callback,
  }),
  getListPlayerSucceed: (data) => ({
    type: GET_LIST_PLAYER_SUCCEED,
    data,
  }),
  getListPlayerFailed: (err) => ({
    type: GET_LIST_PLAYER_FAILED,
    err,
  }),

  predict: (params, callback, fail) => ({
    type: PREDICT,
    params,
    callback,
    fail,
  }),
  predictSucceed: () => ({
    type: PREDICT_SUCCEED,
  }),
  predictFailed: (err) => ({
    type: PREDICT_FAILED,
    err,
  }),

  getPlayHistory: (params, callback) => ({
    type: GET_PLAY_HISTORY,
    params,
    callback,
  }),
  getPlayHistorySucceed: (data) => ({
    type: GET_PLAY_HISTORY_SUCCEED,
    data,
  }),
  getPlayHistoryFailed: (err) => ({
    type: GET_PLAY_HISTORY_FAILED,
    err,
  }),

  gotoTab: (tab) => ({
    type: GOTO_TAB,
    tab,
  }),

  addTurn: (params, callback) => ({
    type: ADD_TURN,
    params,
    callback,
  }),
  addTurnSucceed: () => ({
    type: ADD_TURN_SUCCEED,
  }),
  addTurnFailed: (err) => ({
    type: ADD_TURN_FAILED,
    err,
  }),

  registerService: (params, callback) => ({
    type: REGISTER_SERVICE,
    params,
    callback,
  }),
  registerServiceSucceed: () => ({
    type: REGISTER_SERVICE_SUCCEED,
  }),
  registerServiceFailed: (err) => ({
    type: REGISTER_SERVICE_FAILED,
    err,
  }),

  getListPrize: (params, callback) => ({
    type: GET_LIST_PRIZE,
    params,
    callback,
  }),
  getListPrizeSucceed: (data) => ({
    type: GET_LIST_PRIZE_SUCCEED,
    data,
  }),
  getListPrizeFailed: (err) => ({
    type: GET_LIST_PRIZE_FAILED,
    err,
  }),

  validateTurn: (params, callback) => ({
    type: VALIDATE_TURN,
    params,
    callback,
  }),
  validateTurnSucceed: () => ({
    type: VALIDATE_TURN_SUCCEED,
  }),
  validateTurnFailed: (err) => ({
    type: VALIDATE_TURN_FAILED,
    err,
  }),
};
