import { PRIMARY_COLOR } from "../../consts";

const resultStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 12,
    paddingBottom: 63,
    "& .ant-tabs-tab-active .ant-tabs-tab-btn": {
      color: PRIMARY_COLOR,
      fontWeight: 600,
    },
    "& .ant-tabs-tab-btn": {
      color: "#262626",
      fontWeight: 600,
    },
  },
  option: {
    color: `${PRIMARY_COLOR}`,
  },
  select: {
    borderRadius: "6px",
    border: `1px solid ${PRIMARY_COLOR}`,
    background: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
    marginBottom: "20px",
    "& span": {
      color: `${PRIMARY_COLOR}`,
    },
  },
  card: {
    marginBottom: "8px",
    marginTop: "8px",
    borderRadius: "6px",
    color: "rgba(0, 0, 0, 0.87)",
    background: "#fff",
    width: "100%",
    border: `1px solid ${PRIMARY_COLOR}`,
    padding: 30,
    boxShadow:
      "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: "0",
    wordWrap: "break-word",
    fontSize: ".875rem",
    transition: "all 300ms linear",
  },
  btn: {
    fontSize: window.innerWidth < 350 ? 12 : 13,
    backgroundColor: PRIMARY_COLOR,
    borderRadius: 5,
    color: "white",
    border: "none",
    padding: "8px 15px 6px 15px",
    height: "min-content",
    "&:hover": {
      backgroundColor: "white",
      border: `1px solid ${PRIMARY_COLOR}`,
      color: PRIMARY_COLOR,
    },
    "&:focus": {
      backgroundColor: "white",
      border: `1px solid ${PRIMARY_COLOR}`,
      color: PRIMARY_COLOR,
    },
    "& span": {
      fontWeight: 600,
      letterSpacing: 0.1,
      textTransform: "uppercase",
      lineHeight: "18px",
    },
  },
  titleCard: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: "16px",
    color: PRIMARY_COLOR,
    textTransform: "uppercase",
    marginBottom: 25,
  },
  flags: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  flag: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 90,
    "& img": {
      height: 52,
      objectFit: "contain",
      boxShadow: "0px 1px 5px rgba(0,0,0,0.2)",
      borderRadius: 26,
    },
    "& span": {
      lineHeight: "18px",
      height: 36,
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: "0.1px",
      marginTop: 18,
      textAlign: "center",
    },
  },
  flagvs: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px 8px",
    "& div": {
      fontSize: 20,
      lineHeight: "51px",
      fontWeight: "600",
      color: PRIMARY_COLOR,
      textAlign: "center",
    },
    "& span": {
      fontSize: 10,
      lineHeight: "18px",
      color: PRIMARY_COLOR,
      textAlign: "center",
      marginTop: 18,
    },
  },
  place: {
    marginTop: "0 !important",
  },
  matchDesc: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "18px",
    color: PRIMARY_COLOR,
    fontFamily: "Montserrat",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  tableHead: {
    display: "flex",
    alignItems: "center",
    "& span": {
      fontSize: 13,
      fontWeight: 600,
      fontFamily: "Montserrat",
      lineHeight: "16px",
    },
  },
  divider: {
    width: "100%",
    borderTop: "1px solid #D7D7D7",
    margin: "3px 0",
  },
  col1: {
    width: "10%",
    textAlign: "center",
  },
  col2: {
    width: "34%",
    textAlign: "center",
  },
  col3: {
    width: "34%",
    textAlign: "center",
  },
  col4: {
    width: "22%",
    textAlign: "center",
  },
  contentTable: {
    display: "flex",
    alignItems: "center",
    padding: "12px 0",
    "& span": {
      fontSize: 13,
      lineHeight: "16px",
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 8,
  },
  btnPagination: {
    border: "0.7px solid rgba(0,0,0,0.1)",
    width: 30,
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    marginRight: 8,
    marginTop: 8,
    color: "rgba(0,0,0,0.6)",
    backgroundColor: "white",
    "&:hover": {
      boxShadow: "1px 2px 6px #888888",
    },
    "&:focus": {
      opacity: 0.6,
    },
  },
};

export default resultStyles;
