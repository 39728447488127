import { PRIMARY_COLOR } from "../../../consts";

const championStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    "& img": {
      objectFit: "contain",
      width: "100%",
    },
  },
  option: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 17,
    "& input": {
      width: 150,
    },
  },
  optionLabel: {
    flex: 1,
    fontSize: 13,
    fontWeight: 600,
    lineHeight: "16px",
  },
  championDropDown: {
    width: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& span": {
      textAlign: "start",
    },
  },
  btnAuction: {
    fontSize: 13,
    backgroundColor: PRIMARY_COLOR,
    borderRadius: 5,
    color: "white",
    border: "none",
    padding: "8px 16px 6px 16px",
    height: "min-content",
    width: "min-content",
    alignSelf: "center",
    marginTop: 30,
    "&:hover": {
      backgroundColor: "white",
      border: `1px solid ${PRIMARY_COLOR}`,
      color: PRIMARY_COLOR,
    },
    "&:focus": {
      backgroundColor: "white",
      border: `1px solid ${PRIMARY_COLOR}`,
      color: PRIMARY_COLOR,
    },
    "& span": {
      fontWeight: 600,
      letterSpacing: 0.1,
      textTransform: "uppercase",
      lineHeight: "18px",
    },
  },
  listPrize: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: "16px",
    margin: "20px 0",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 8,
  },
  btnPagination: {
    border: "0.7px solid rgba(0,0,0,0.1)",
    width: 30,
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    marginRight: 8,
    marginTop: 8,
    color: "rgba(0,0,0,0.6)",
    backgroundColor: "white",
    "&:hover": {
      boxShadow: "1px 2px 6px #888888",
    },
    "&:focus": {
      opacity: 0.6,
    },
  },
  table: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  tableHead: {
    display: "flex",
    alignItems: "center",
    "& span": {
      fontSize: 13,
      fontWeight: 600,
      fontFamily: "Montserrat",
      lineHeight: "16px",
    },
  },
  divider: {
    width: "100%",
    borderTop: "1px solid #D7D7D7",
    margin: "3px 0",
  },
  colPrize1: {
    width: "10%",
    textAlign: "center",
  },
  colPrize2: {
    width: "35%",
    textAlign: "center",
  },
  colPrize3: {
    width: "35%",
    textAlign: "center",
  },
  colPrize4: {
    width: "20%",
    textAlign: "center",
  },
  contentTable: {
    display: "flex",
    alignItems: "center",
    padding: "12px 0",
    "& span": {
      fontSize: 13,
      lineHeight: "16px",
    },
  },
};
export default championStyles;
