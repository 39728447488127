import BaseRequest from "./BaseRequest";

export default class HomeRequest extends BaseRequest {
  url = "";
  /**
   * @param {string} params.isdn
   * @param {string} params.gameCode
   * @param {string} params.language
   * @param {int} type
   * @param {int} params.pageNumber
   * @param {int} params.pageSize
   * @returns {Promise<BaseRequest._responseHandler.props.data|undefined>}
   */
  getPredictSession(params) {
    let data = {
      isWrap: "1",
      wsCode: "wsGetListPredictionSession",
      wsRequest: params,
    };
    return this.post(this.url, data);
  }

  /**
   * @param {string} params.isdn
   * @param {string} params.gameCode
   * @param {string} params.language
   * @param {string} params.teamId
   * @param {string} params.teamName
   * @param {string} params.state
   * @param {int} params.pageNumber
   * @param {int} params.pageSize
   * @returns {Promise<BaseRequest._responseHandler.props.data|undefined>}
   */
  getListTeam(params) {
    let data = {
      isWrap: "1",
      wsCode: "wsGetListTeam",
      wsRequest: params,
    };
    return this.post(this.url, data);
  }

  /**
   * @param {string} params.isdn
   * @param {string} params.gameCode
   * @param {string} params.language
   * @param {string} params.teamId
   * @param {string} params.playerName
   * @param {string} params.state
   * @param {int} params.pageNumber
   * @param {int} params.pageSize
   * @returns {Promise<BaseRequest._responseHandler.props.data|undefined>}
   */
  getListPlayer(params) {
    let data = {
      isWrap: "1",
      wsCode: "wsGetListPlayer",
      wsRequest: params,
    };
    return this.post(this.url, data);
  }

  /**
   * @param {string} params.isdn
   * @param {string} params.gameCode
   * @param {string} params.language
   * @param {string} params.predictionSession
   * @param {string} params.predictionType
   * @param {string} params.predictionValue
   * @param {string} params.answer
   * @returns {Promise<BaseRequest._responseHandler.props.data|undefined>}
   */
  predict(params) {
    let data = {
      isWrap: "1",
      wsCode: "wsPredictionPlayTurn",
      wsRequest: params,
    };
    return this.post(this.url, data);
  }

  /**
   * @param {string} params.isdn
   * @param {string} params.gameCode
   * @param {string} params.language
   * @param {string} params.predictionSession
   * @param {string} params.predictionType
   * @param {int} params.pageNumber
   * @param {int} params.pageSize
   * @returns {Promise<BaseRequest._responseHandler.props.data|undefined>}
   */
  getPlayHistory(params) {
    let data = {
      isWrap: "1",
      wsCode: "wsGetPlayHistory",
      wsRequest: params,
    };
    return this.post(this.url, data);
  }

  /**
   * @param {string} isdn
   * @param {string} gameCode
   * @param {string} otp
   * @param {string} transid
   * @param {string} otpType
   * @param {string} language
   * @returns {Promise<BaseRequest._responseHandler.props.data|undefined>}
   */
  addTurn(params) {
    let data = {
      isWrap: "1",
      wsCode: "wsChargeBolaService",
      wsRequest: params,
    };
    return this.post(this.url, data);
  }

  /**
   * @param {string} isdn
   * @param {string} gameCode
   * @param {string} language
   * @param {string} otpType
   * @param {string} transid
   * @returns {Promise<BaseRequest._responseHandler.props.data|undefined>}
   */
  registerService(params) {
    let data = {
      isWrap: "1",
      wsCode: "wsRegisterBolaService",
      wsRequest: params,
    };
    return this.post(this.url, data);
  }

  /**
   * @param {string} isdn
   * @param {string} gameCode
   * @param {string} language
   * @param {string} pageNumber
   * @param {string} pageSize
   * @returns {Promise<BaseRequest._responseHandler.props.data|undefined>}
   */
  getListPrize(params) {
    let data = {
      isWrap: "1",
      wsCode: "wsGetListPrize",
      wsRequest: params,
    };
    return this.post(this.url, data);
  }

  /**
   * @param {string} isdn
   * @param {string} language
   * @returns {Promise<BaseRequest._responseHandler.props.data|undefined>}
   */
  validateTurn(params) {
    console.log(params, "params");
    let data = {
      isWrap: "1",
      wsCode: "wsValidatePredictionTurn",
      wsRequest: params,
    };
    return this.post(this.url, data);
  }

  getFootballLeague(params) {
    let data = {
      isWrap: "1",
      wsCode: "wsGetFootballLeague",
      wsRequest: params,
    };
    return this.post(this.url, data);
  }

  getClubFootball(params) {
    let data = {
      isWrap: "1",
      wsCode: "wsGetListClub",
      wsRequest: params,
    };
    return this.post(this.url, data);
  }
}
