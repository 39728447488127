import { SET_SUBPAGE } from "../actions/common/action_types";

const defaultParams = {
  subPage: "",
};

export default (state = defaultParams, action) => {
  switch (action.type) {
    case SET_SUBPAGE:
      return {
        ...state,
        subPage: action.subPage,
      };
    default:
      return {
        ...state,
      };
  }
};
