import {
  GET_LIST_PRIZE_RESULT,
  GET_LIST_PRIZE_RESULT_FAILED,
  GET_LIST_PRIZE_RESULT_SUCCEED,
  GET_PREDICT_RESULT,
  GET_PREDICT_RESULT_DETAIL,
  GET_PREDICT_RESULT_DETAIL_FAILED,
  GET_PREDICT_RESULT_DETAIL_SUCCEED,
  GET_PREDICT_RESULT_FAILED,
  GET_PREDICT_RESULT_SUCCEED,
} from "./action_types";

export default {
  getPredictResult: (params, callback) => ({
    type: GET_PREDICT_RESULT,
    params,
    callback,
  }),
  getPredictResultSucceed: (data, predictionType) => ({
    type: GET_PREDICT_RESULT_SUCCEED,
    data,
    predictionType: predictionType || 1,
  }),
  getPredictResultFailed: (err) => ({
    type: GET_PREDICT_RESULT_FAILED,
    err,
  }),

  getPredictResultDetail: (params, callback) => ({
    type: GET_PREDICT_RESULT_DETAIL,
    params,
    callback,
  }),
  getPredictResultDetailSucceed: (data, predictionType) => ({
    type: GET_PREDICT_RESULT_DETAIL_SUCCEED,
    data,
    predictionType: predictionType || 1,
  }),
  getPredictResultDetailFailed: (err) => ({
    type: GET_PREDICT_RESULT_DETAIL_FAILED,
    err,
  }),

  getListPrizeResult: (params, callback) => ({
    type: GET_LIST_PRIZE_RESULT,
    params,
    callback,
  }),
  getListPrizeResultSucceed: (data) => ({
    type: GET_LIST_PRIZE_RESULT_SUCCEED,
    data,
  }),
  getListPrizeResultFailed: (err) => ({
    type: GET_LIST_PRIZE_RESULT_FAILED,
    err,
  }),
};
