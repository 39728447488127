import React from "react";
import styles from "assets/jss/result";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Select } from "antd";
import { useTranslation } from "react-i18next";
import utils from "../common/utils";
import consts from "../consts";
import _ from "lodash";
import DotLoading from "../components/Loading/DotLoading";

const useStyles = makeStyles(styles);

function Result(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation("translation");
  const [loading, setLoading] = React.useState(false);

  function onChange(value) {
    getPredictResultView(value);
  }

  function onBlur() {
    console.log("blur");
  }

  function onFocus() {
    console.log("focus");
  }

  const getPredictResultView = (round) => {
    setLoading(true);
    props.getPredictResult(
      {
        isdn: utils.isdn(),
        gameCode: consts.GAME_CODE,
        language: i18n.language,
        leagueId: localStorage.getItem("leagueId"),
        matchRound: round,
        predictionSession: "",
        predictionType: "1",
        matchId: "",
        pageNumber: 1,
        pageSize: consts.PAGE_SIZE,
      },
      () => {
        setLoading(false);
      }
    );
  };

  React.useEffect(() => {
    getPredictResultView(1);
    if (props.result != null) {
      setLoading(false);
    }
  }, [props.tab]);

  return (
    <div className={classes.container}>
      <Select
        className={classes.select}
        placeholder={t("selectRound")}
        optionFilterProp="children"
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Select.Option className={classes.option} value={1}>
          {t("round")} 1
        </Select.Option>
        <Select.Option className={classes.option} value={2}>
          {t("round")} 2
        </Select.Option>
        <Select.Option className={classes.option} value={3}>
          {t("round")} 3
        </Select.Option>
        <Select.Option className={classes.option} value={4}>
          {t("round")} 4
        </Select.Option>
        <Select.Option className={classes.option} value={5}>
          {t("round")} 5
        </Select.Option>
        <Select.Option className={classes.option} value={6}>
          {t("round")} 6
        </Select.Option>
        <Select.Option className={classes.option} value={7}>
          {t("round")} 7
        </Select.Option>
        <Select.Option className={classes.option} value={8}>
          {t("round")} 8
        </Select.Option>
        <Select.Option className={classes.option} value={9}>
          {t("round")} 9
        </Select.Option>
        <Select.Option className={classes.option} value={10}>
          {t("round")} 10
        </Select.Option>
        <Select.Option className={classes.option} value={11}>
          {t("round")} 11
        </Select.Option>
        <Select.Option className={classes.option} value={12}>
          {t("round")} 12
        </Select.Option>
        <Select.Option className={classes.option} value={13}>
          {t("round")} 13
        </Select.Option>
        <Select.Option className={classes.option} value={14}>
          {t("round")} 14
        </Select.Option>
        <Select.Option className={classes.option} value={15}>
          {t("round")} 15
        </Select.Option>
        <Select.Option className={classes.option} value={16}>
          {t("round")} 16
        </Select.Option>
        <Select.Option className={classes.option} value={17}>
          {t("round")} 17
        </Select.Option>
        <Select.Option className={classes.option} value={18}>
          {t("round")} 18
        </Select.Option>
        <Select.Option className={classes.option} value={19}>
          {t("round")} 19
        </Select.Option>
        <Select.Option className={classes.option} value={20}>
          {t("round")} 20
        </Select.Option>
        <Select.Option className={classes.option} value={21}>
          {t("round")} 21
        </Select.Option>
        <Select.Option className={classes.option} value={22}>
          {t("round")} 22
        </Select.Option>
        <Select.Option className={classes.option} value={23}>
          {t("round")} 23
        </Select.Option>
        <Select.Option className={classes.option} value={24}>
          {t("round")} 24
        </Select.Option>
        <Select.Option className={classes.option} value={25}>
          {t("round")} 25
        </Select.Option>
        <Select.Option className={classes.option} value={26}>
          {t("round")} 26
        </Select.Option>
        <Select.Option className={classes.option} value={27}>
          {t("round")} 28
        </Select.Option>
        <Select.Option className={classes.option} value={28}>
          {t("round")} 28
        </Select.Option>
        <Select.Option className={classes.option} value={29}>
          {t("round")} 29
        </Select.Option>
        <Select.Option className={classes.option} value={30}>
          {t("round")} 30
        </Select.Option>
        <Select.Option className={classes.option} value={31}>
          {t("round")} 31
        </Select.Option>
        <Select.Option className={classes.option} value={32}>
          {t("round")} 32
        </Select.Option>
        <Select.Option className={classes.option} value={33}>
          {t("round")} 33
        </Select.Option>
        <Select.Option className={classes.option} value={34}>
          {t("round")} 34
        </Select.Option>
        <Select.Option className={classes.option} value={35}>
          {t("round")} 35
        </Select.Option>
        <Select.Option className={classes.option} value={36}>
          {t("round")} 36
        </Select.Option>
        <Select.Option className={classes.option} value={37}>
          {t("round")} 37
        </Select.Option>
        <Select.Option className={classes.option} value={38}>
          {t("round")} 38
        </Select.Option>
      </Select>
      <>
        {_.map(props.result, (e) => (
          <div className={classes.card} key={e.id}>
            <span className={classes.matchDesc}>{e.matchDesc}</span>
            <div
              className={classes.flags}
              style={{
                marginBottom: 25,
              }}
            >
              <div className={classes.flag}>
                <img src={utils.getImageUrl(e.firstTeamImageUrl)} />
                <span>{e.firstTeamName}</span>
              </div>
              <div className={classes.flagvs}>
                <div>{e.firstTeam + " - " + e.secondTeam}</div>
                <span>{utils.formatDateMatch(e.matchStartTime)}</span>
                <span className={classes.place}>{e.matchPlace}</span>
              </div>
              <div className={classes.flag}>
                <img src={utils.getImageUrl(e.secondTeamImageUrl)} />
                <span>{e.secondTeamName}</span>
              </div>
            </div>
            <Button
              className={classes.btn}
              onClick={() => {
                props.resultView(e);
              }}
            >
              {t("resultBtn")}{" "}
            </Button>
          </div>
        ))}
      </>
      {loading ? <DotLoading /> : <div />}
    </div>
  );
}

export default Result;
