import BaseRequest from "./BaseRequest";

export default class HomeRequest extends BaseRequest {
  /**
   * @param {string} params.isdn
   * @param {string} params.gameCode
   * @param {string} params.language
   * @returns {Promise<BaseRequest._responseHandler.props.data|undefined>}
   */
  getGuide(params) {
    const url = ``;
    let data = {
      isWrap: "1",
      wsCode: "wsBolaGetGuide",
      wsRequest: params,
    };
    return this.post(url, data);
  }

  /**
   * @param {string} params.isdn
   * @param {string} params.gameCode
   * @param {string} params.language
   * @returns {Promise<BaseRequest._responseHandler.props.data|undefined>}
   */
  getRule(params) {
    const url = ``;
    let data = {
      isWrap: "1",
      wsCode: "wsBolaGetRule",
      wsRequest: params,
    };
    return this.post(url, data);
  }
}
