import React, { Component, Suspense, useEffect } from "react";
import Loading from "./components/Loading/DotLoading";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import actions from "redux/actions/auth";
import common from "redux/actions/common";
import { connect } from "react-redux";
import ModalLogin from "components/Modal/ModalLogin";
import { createBrowserHistory } from "history";
import { useTranslation } from "react-i18next";
import utils from "./common/utils";
import styles from "assets/jss/app";
import { makeStyles } from "@material-ui/core/styles";
import Navigation from "./components/Navigation";
import Predict from "containers/Predict";
import Help from "containers/Help";
import Result from "containers/Result";
import Home from "containers/Home";
import Header from "./components/Header";
import _ from "lodash";

const hist = createBrowserHistory();
const useStyles = makeStyles(styles);

const listSubPage = [
  {
    curr: "euro_home",
    pre: "",
  },
  {
    curr: "predict_detail",
    pre: "",
  },
  {
    curr: "result_match",
    pre: "",
  },
  {
    curr: "result_team",
    pre: "",
  },
  {
    curr: "result_player",
    pre: "",
  },
];

class ProtectedRoute extends Component {
  render() {
    const {
      component: Component,
      authenticated,
      openLogin,
      ...props
    } = this.props;

    return (
      <Route
        {...props}
        render={(props) => {
          if (authenticated) {
            return <Component {...props} />;
          } else {
            openLogin();
            // hist.replace('/')
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Loading />
              </div>
            );
          }
        }}
      />
    );
  }
}

function App(props) {
  const classes = useStyles();
  const { t } = useTranslation("translation");
  console.log(hist, "hist", hist.location.pathname.lastIndexOf("/") + 1);

  React.useEffect(() => {
    hist.listen(() => {
      props.setSubPage("");
    });
  }, []);

  useEffect(() => {
    if (props.isLogout) {
      console.log("isLogout");
      hist.push("/play");
      utils.showNotification(
        t("logout-modal-notification"),
        t("logout-notification-success")
      );
      props.cancelLogout();
    }
  }, [props.isLogout]);

  return (
    <div className={classes.container}>
      <div className={classes.container2}>
        <HashRouter history={hist}>
          <Suspense fallback={<Loading />}>
            <Header
              {...props}
              icon={props.subPage && props.subPage != "" ? true : false}
              onBackPage={() => {
                let page = _.find(listSubPage, (e) => e.curr == props.subPage);
                props.setSubPage(page.pre || "");
              }}
            />
            <Switch>
              <Route path={"/home"} exact component={Home} />

              <ProtectedRoute
                path={"/predict"}
                exact
                authenticated={localStorage.getItem("token") ? true : false}
                // authenticated={true}
                component={Predict}
                openLogin={props.openLogin}
              />
              <Route path={"/result"} exact component={Result} />
              <Route path={"/help"} exact component={Help} />
              <Redirect from="/" to={"/home"} />
              {/*<Redirect from="/play" to={"/play"}/>*/}
            </Switch>
            <Navigation
              active={hist.location.hash.substring(2)}
              style={{ position: "fixed", bottom: 0 }}
              key={"navigation"}
            />
          </Suspense>
        </HashRouter>
        {props.openModalLogin ? (
          <ModalLogin
            visible={props.openModalLogin}
            handleOk={() => {
              hist.push("/home");
            }}
            handleCancel={() => {
              props.cancelModalLogin();
            }}
            {...props}
          />
        ) : (
          <div />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  openModalLogin: state.auth.openModalLogin,
  isLogout: state.auth.isLogout,
  subPage: state.common.subPage,
});

const mapDispatchToProps = (dispatch) => ({
  login: (params, callback) => {
    dispatch(actions.login(params, callback));
  },
  logout: () => {
    dispatch(actions.logout());
  },
  openLogin: () => {
    dispatch(actions.openModalLogin());
  },
  cancelModalLogin: () => {
    dispatch(actions.cancelModalLogin());
  },
  sendOtp: (params, callback) => {
    dispatch(actions.getOtp(params, callback));
  },
  cancelLogout: () => {
    dispatch(actions.logoutSucceed());
  },
  setSubPage: (subPage) => {
    dispatch(common.setSubPage(subPage));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
