const homeStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 12,
    paddingBottom: 62,
    // backgroundColor: "rgba(0,0,0,0.05)",
  },
  container2: {
    border: "1px solid #fd7e14",
  },
  textLeagueDesc: {
    // position: "absolute",
    marginLeft: 4,
    marginTop: 10,
    height: 18,

    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    // lineHeight: 18,

    /* identical to box height, or 129% */
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: 0.1,

    color: "#000000",
  },
  spanText: {
    height: "100%",
    display: "contents",
  },
  spanTextDesc: {
    height: "100%",
    display: "contents",
  },
  textLeagueName: {
    // position: "absolute",
    // marginLeft: 10,
    marginTop: 5,

    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    // lineHeight: 18,

    /* identical to box height, or 129% */
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: 0.1,

    color: "#000000",
  },
  imageNation: {
    width: "90%",
    borderRadius: "100%",
  },
  imageAuction: {
    objectFit: "contain",
    width: "90%",
    borderRadius: "10%",
    pointer: "cursor",
  },
  divImage: {
    display: "inline-block",
    width: "100%",
    borderBottom: "1px solid #beb2b240",
    padding: 20,
    height: 100,
    backgroundColor: "#ffffff",
    // boxShadow: "rgba(0,0,0,0.8) 0 0 10px",
    pointer: "cursor",
  },
  warning: {
    display: "inline-block",
    margin: "10px 7px",
    fontSize: "13px",
    textAlign: "center",
  },
};
export default homeStyles;
