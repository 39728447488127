import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/navigation.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import {
  faHistory,
  faHome,
  faPlayCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

function Navigation(props) {
  const classes = useStyles();
  const [active, setActive] = useState("home");
  const { t } = useTranslation("translation");

  const defaultRoutes = [
    {
      icon: faHome,
      type: "fontawesome",
      title: t("home"),
      id: "home",
    },
    {
      icon: faPlayCircle,
      type: "fontawesome",
      title: t("predict"),
      id: "predict",
    },
    {
      icon: faHistory,
      type: "fontawesome",
      title: t("result"),
      id: "result",
    },
    {
      icon: faQuestionCircle,
      type: "fontawesome",
      title: t("help"),
      id: "help",
    },
  ];
  const routes = props.routes || defaultRoutes;

  useEffect(() => {
    if (props.active && props.active != "") setActive(props.active);
  }, [props.active]);

  return (
    <div className={classes.container} {...props}>
      {_.map(routes, (e) => (
        <Link
          key={e.id}
          className={classes.item}
          to={"/" + e.id}
          onClick={() => {
            setActive(e.id);
          }}
        >
          {e.type == "fontawesome" ? (
            <FontAwesomeIcon
              icon={e.icon}
              className={
                active == e.id ? classes.iconActionActive : classes.iconActions
              }
            />
          ) : (
            <e.icon
              className={
                active == e.id ? classes.imageIconActive : classes.image
              }
            />
          )}
          <span
            className={active == e.id ? classes.titleActive : classes.title}
          >
            {e.title}
          </span>
        </Link>
      ))}
    </div>
  );
}

export default Navigation;
