import { connect } from "react-redux";
import Help from "../views/Help";
import actions from "redux/actions/help";

const mapStateToProps = (state) => ({
  rule: state.help.rule,
  guide: state.help.guide,
});

const mapDispatchToProps = (dispatch) => ({
  getGuide: (params, callback) => {
    dispatch(actions.getGuide(params, callback));
  },
  getRule: (params, callback) => {
    dispatch(actions.getRule(params, callback));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Help);
