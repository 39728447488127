import {
  CANCEL_MODAL_LOGIN,
  CANCEL_MODAL_LOGIN_FAILED,
  CANCEL_MODAL_LOGIN_SUCCEED,
  GET_OTP,
  GET_OTP_FAILED,
  GET_OTP_SUCCEED,
  LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCEED,
  LOGOUT,
  LOGOUT_FAILED,
  LOGOUT_SUCCEED,
  OPEN_MODAL_LOGIN,
  OPEN_MODAL_LOGIN_FAILED,
  OPEN_MODAL_LOGIN_SUCCEED,
} from "./action_types";

export default {
  login: (params, callback) => ({
    type: LOGIN,
    params,
    callback,
  }),
  loginSucceed: (token, isdn) => ({
    type: LOGIN_SUCCEED,
    token,
    isdn,
  }),
  loginFailed: (err) => ({
    type: LOGIN_FAILED,
    err,
  }),

  getOtp: (params, callback) => ({
    type: GET_OTP,
    params,
    callback,
  }),
  getOtpSucceed: (data) => ({
    type: GET_OTP_SUCCEED,
    data,
  }),
  getOtpFailed: (err) => ({
    type: GET_OTP_FAILED,
    err,
  }),

  openModalLogin: () => ({
    type: OPEN_MODAL_LOGIN,
  }),
  openModalLoginSucceed: (data) => ({
    type: OPEN_MODAL_LOGIN_SUCCEED,
    data,
  }),
  openModalLoginFailed: (err) => ({
    type: OPEN_MODAL_LOGIN_FAILED,
    err,
  }),

  cancelModalLogin: () => ({
    type: CANCEL_MODAL_LOGIN,
  }),
  cancelModalLoginSucceed: () => ({
    type: CANCEL_MODAL_LOGIN_SUCCEED,
  }),
  cancelModalLoginFailed: (err) => ({
    type: CANCEL_MODAL_LOGIN_FAILED,
    err,
  }),

  logout: () => ({
    type: LOGOUT,
  }),
  logoutSucceed: () => ({
    type: LOGOUT_SUCCEED,
  }),
  logoutFailed: (err) => ({
    type: LOGOUT_FAILED,
    err,
  }),
};
