import React from "react";
import styles from "assets/jss/home";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from "antd";
import _ from "lodash";
import Loading from "../components/Loading/DotLoading";
import { useTranslation } from "react-i18next";
import img18 from "assets/img/18.png";

const useStyles = makeStyles(styles);

function Home(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const { i18n } = useTranslation("translation");

  const getFootballLeagues = () => {
    setLoading(true);
    props.getFootballLeagues(
      {
        gameCode: "PREDICTION_FOOTBALL",
        language: i18n.language,
        pageNumber: 1,
        pageSize: 100,
      },
      () => {
        console.log(props.leagues);
        setLoading(false);
      }
    );
  };

  React.useEffect(() => {
    getFootballLeagues();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.container2}>
        <Row gutter={16}>
          {_.map(props.leagues, (e) => (
            <Col span={24}>
              <div
                className={classes.divImage}
                onClick={() => {
                  props.gotoTab("1");
                  props.history.push("predict");
                  localStorage.setItem("leagueId", e.leagueId);
                  localStorage.setItem("leagueName", e.leagueName);
                  localStorage.setItem("imageUrl", e.imageUrl);
                }}
              >
                <Row gutter={16}>
                  <Col span={6}>
                    <img src={e.imageUrl} className={classes.imageAuction} />
                  </Col>
                  <Col span={18}>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Row gutter={16}>
                          <Col span={6}>
                            <img
                              src={e.nationFlagImgUrl}
                              className={classes.imageNation}
                            />
                          </Col>
                          <Col span={18} className={classes.textLeagueName}>
                            <span className={classes.spanText}>
                              {e.leagueName}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={18} className={classes.textLeagueDesc}>
                        <span className={classes.spanTextDesc}>
                          {e.leagueDesc}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      {loading ? <Loading /> : <div />}
      <div className={classes.warning}>
        <img width={30} src={img18} style={{ marginRight: "5px" }} />
        <span>
          Deve ter 18 anos ou mais para se inscrever ou jogar na Mozambique
          Beneficial Games LDA (MBG).
        </span>
      </div>
    </div>
  );
}

export default Home;
