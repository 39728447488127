import { isSafari } from "react-device-detect";
import { PRIMARY_COLOR } from "../../consts";

const helpStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    // marginTop: 21,
    width: "100%",
    alignItems: "center",
    "& .ant-tabs-tab-active .ant-tabs-tab-btn": {
      color: PRIMARY_COLOR,
      fontWeight: 400,
    },
    "& .ant-tabs-tab-btn": {
      color: "#262626",
      fontWeight: 400,
      maxWidth: 135,
      whiteSpace: "normal",
      textAlign: "center",
      wordBreak: "break-word",
    },
    "& .ant-tabs-nav-list": {
      width: "100%",
    },
    "& .ant-tabs": {
      flex: 1,
      backgroundColor: "white",
      marginBottom: 62,
      maxHeight: `100%`,
      width: "100%",
      overflowY: isSafari ? "scroll" : "auto",
      "-webkit-overflow-scrolling": "touch",
    },
    "& .ant-tabs-tab": {
      width: "50%",
      justifyContent: "center",
    },
    "& .ant-tabs-ink-bar": {
      width: "50%",
      backgroundColor: PRIMARY_COLOR,
    },
    "& .ant-tabs-content-holder": {
      overflowY: "auto",
    },
  },
};

export default helpStyles;
