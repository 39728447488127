import BaseRequest from "./BaseRequest";

export default class ResultRequest extends BaseRequest {
  url = "";
  /**
   * @param {string} params.isdn
   * @returns {Promise<BaseRequest._responseHandler.props.data|undefined>}
   */
  getResult(params) {
    let data = {
      isWrap: "1",
      wsCode: "wsGetListPredictionResult",
      wsRequest: params,
    };
    return this.post(this.url, data);
  }

  /**
   * @param {string} params.isdn
   * @returns {Promise<BaseRequest._responseHandler.props.data|undefined>}
   */
  getResultDetail(params) {
    let data = {
      isWrap: "1",
      wsCode: "wsGetPredictionResultDetail",
      wsRequest: params,
    };
    return this.post(this.url, data);
  }

  /**
   * @param {string} isdn
   * @param {string} gameCode
   * @param {string} language
   * @param {string} predictionSession
   * @param {string} pageNumber
   * @param {string} pageSize
   * @returns {Promise<BaseRequest._responseHandler.props.data|undefined>}
   */
  getListPrizeResult(params) {
    let data = {
      isWrap: "1",
      wsCode: "wsGetListPrizeResult",
      wsRequest: params,
    };
    return this.post(this.url, data);
  }
}
