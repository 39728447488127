import React from "react";
import ReactDOM from "react-dom";
import "./env";
import "antd/dist/antd.css";
import createSagaMiddleware from "redux-saga";
import "assets/scss/material-kit-react.scss?v=1.9.0";
import "./i18n";
import { BASE_URL } from "./consts";
import { applyMiddleware, compose, createStore } from "redux";
import allReducers from "./redux/reducers";
import rootSaga from "./redux/sagas/root_saga";
import axios from "axios";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

window.axios = axios.create({
  baseURL: BASE_URL,
});

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const store = createStore(
  allReducers,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

window.$dispatch = store.dispatch;
store.dispatch({
  type: "@@__INIT__",
});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
