export const GET_PREDICT_RESULT = "GET_PREDICT_RESULT";
export const GET_PREDICT_RESULT_SUCCEED = "GET_PREDICT_RESULT_SUCCEED";
export const GET_PREDICT_RESULT_FAILED = "GET_PREDICT_RESULT_FAILED";

export const GET_PREDICT_RESULT_DETAIL = "GET_PREDICT_RESULT_DETAIL";
export const GET_PREDICT_RESULT_DETAIL_SUCCEED =
  "GET_PREDICT_RESULT_DETAIL_SUCCEED";
export const GET_PREDICT_RESULT_DETAIL_FAILED =
  "GET_PREDICT_RESULT_DETAIL_FAILED";

export const GET_LIST_PRIZE_RESULT = "GET_LIST_PRIZE_RESULT";
export const GET_LIST_PRIZE_RESULT_SUCCEED = "GET_LIST_PRIZE_RESULT_SUCCEED";
export const GET_LIST_PRIZE_RESULT_FAILED = "GET_LIST_PRIZE_RESULT_FAILED";
