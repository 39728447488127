import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/form/FormItem";
import { PRIMARY_COLOR } from "consts";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

function OtpItem(props) {
  const classes = useStyles();
  const [count, setCount] = useState(60);
  const [isReSend, setIsResend] = useState(false);
  const {
    placeholder,
    name,
    reset,
    value,
    disabled,
    autoComplete,
    reSend,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (!count) {
      setIsResend(true);
      setCount(60);
    }
    const intervalId = setInterval(() => {
      if (!isReSend) setCount(count - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [count]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
        width: "100%",
      }}
    >
      {autoComplete ? (
        <Input
          placeholder={placeholder || ""}
          name={name}
          className={classes.inputFormItem}
          onFocus={reset}
          defaultValue={value || ""}
          disabled={disabled}
          autoComplete={autoComplete}
        />
      ) : (
        <Input
          placeholder={placeholder || ""}
          name={name}
          className={classes.inputFormItem}
          onFocus={reset}
          defaultValue={value || ""}
          disabled={disabled}
        />
      )}
      {isReSend ? (
        <div
          onClick={() => {
            setIsResend(false);
            reSend();
          }}
          style={{
            fontSize: 13,
            fontWeight: 400,
            lineHeight: "17px",
            marginTop: 8,
            marginBottom: 8,
            textDecoration: "underline",
          }}
        >
          {t("getOtp")}
        </div>
      ) : (
        <span
          style={{
            fontSize: 13,
            fontWeight: 400,
            lineHeight: "17px",
            marginTop: 8,
            marginBottom: 8,
          }}
        >
          {t("sendOtpBack")}{" "}
          <span style={{ color: PRIMARY_COLOR }}>{count}s</span>
        </span>
      )}
    </div>
  );
}

export default OtpItem;
