import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import styles from "assets/jss/modal";
import { PRIMARY_COLOR } from "consts";
import { makeStyles } from "@material-ui/core/styles";
import "assets/css/modal_repair.css";
import { useTranslation } from "react-i18next";
import OtpItem from "./OtpItem";

const useStyles = makeStyles(styles);

function OTPModal(props) {
  const classes = useStyles();
  const { visible, handleOk, handleCancel, otpError, title, reSend } = props;
  const [loading] = useState(false);
  const { t } = useTranslation("translation");
  const [defaultValue, setDefaultValue] = useState("");

  const onFinish = (event) => {
    event.preventDefault();
    const { otpInput } = event.target;
    if (otpInput) {
      if (otpInput.value == "") {
        return;
      }
      handleOk(otpInput.value);
    }
  };

  const getOtpFromSMS = () => {
    if (!window.OTPCredential) {
      console.log("browser not support");
      return;
    }
    window.addEventListener("DOMContentLoaded", () => {
      const abort = new AbortController();

      setTimeout(() => {
        // abort after two minutes
        abort.abort();
      }, 2 * 60 * 1000);
      navigator.credentials
        .get({
          signal: abort.signal,
          otp: {
            transport: ["sms"],
          },
        })
        .then(({ code }) => {
          if (code) {
            setDefaultValue(code);
          }
          abort.abort();
        });
    });
  };

  useEffect(() => {
    getOtpFromSMS();
  }, []);

  return (
    <>
      <Modal
        title=""
        destroyOnClose={true}
        visible={visible}
        onOk={() => {}}
        onCancel={handleCancel}
        width={300}
        closable={false}
        bodyStyle={{ borderRadius: 10 }}
        footer={[
          <form
            name="otp"
            onSubmit={onFinish}
            className={classes.formItem}
            key={"otpModal"}
          >
            <OtpItem
              label={"Otp"}
              require={true}
              placeholder={t("otpPlaceholder")}
              errorMessage={otpError}
              name={"otpInput"}
              autoComplete={"one-time-code"}
              defaultValue={defaultValue}
              reSend={reSend}
            />
            <Button
              style={{ backgroundColor: PRIMARY_COLOR }}
              className={classes.btnModal}
              type={"submit"}
              loading={loading}
              htmlType="submit"
            >
              {t("labelConfirmOtp")}
            </Button>
          </form>,
        ]}
      >
        <div style={{ textAlign: "center" }}>
          <span
            style={{
              fontSize: 13,
              lineHeight: "18px",
              color: "#181725",
              fontWeight: 600,
            }}
          >
            {title || ""}
          </span>
        </div>
      </Modal>
    </>
  );
}

export default OTPModal;
