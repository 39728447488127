import { connect } from "react-redux";
import Result from "../views/Result";
import common from "../redux/actions/common";
import actions from "redux/actions/result";
import actionsPredict from "redux/actions/predict";

const mapStateToProps = (state) => ({
  subPage: state.common.subPage,
  result: state.result.result,
  match: state.result.match,
  team: state.result.team,
  player: state.result.player,
  prize: state.result.prize,
  leagues: state.predict.leagues,
});

const mapDispatchToProps = (dispatch) => ({
  setSubPage: (subPage) => {
    dispatch(common.setSubPage(subPage));
  },
  getPredictResultDetail: (params, callback) => {
    dispatch(actions.getPredictResultDetail(params, callback));
  },
  getPredictResult: (params, callback) => {
    dispatch(actions.getPredictResult(params, callback));
  },
  getListPrizeResult: (params, callback) => {
    dispatch(actions.getListPrizeResult(params, callback));
  },
  getFootballLeagues: (param, callback) => {
    dispatch(actionsPredict.getFootballLeague(param, callback));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Result);
