import React from "react";
import consts, { PRIMARY_COLOR } from "consts";
import { Button, Input, Select, Tabs } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/predict/predictDetail";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import utils from "../../common/utils";
import Loading from "../Loading/DotLoading";
import AcceptModal from "../Modal/AcceptModal";

const { TabPane } = Tabs;

const useStyles = makeStyles(styles);

function PredictDetail(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation("translation");
  const [tab, setTab] = React.useState("1");
  const [champion, setChampion] = React.useState(props.match.matchOption[0]);
  const [loading, setLoading] = React.useState(false);
  const [answer, setAnswer] = React.useState("0");
  const [acceptModal, setAcceptModal] = React.useState(false);
  const [pagePrize, setPagePrize] = React.useState(1);
  const [options, setOptions] = React.useState([]);

  // const [menu, setMenu] = React.useState(
  //   <Menu>
  //     {_.map(props.match.matchOption || [], (e) => (
  //       <Menu.Item onClick={() => setChampion(e)} key={e.keyOption}>
  //         <span>{e.nameOtion || "Draw"}</span>
  //       </Menu.Item>
  //     ))}
  //   </Menu>
  // );

  const predict = () => {
    setLoading(true);
    props.predict(
      {
        isdn: utils.isdn(),
        gameCode: consts.GAME_CODE,
        language: i18n.language,
        predictionSession: props.match.id,
        predictionType: props.match.predictType,
        predictionValue: champion.keyOption,
        token: localStorage.getItem("token"),
        answer: parseInt(answer),
      },
      () => {
        setLoading(false);
      },
      (result) => {
        switch (result.errorCode) {
          case "2":
            props.openModal(2);
            break;
          case "11":
            props.openModal(1);
            break;
          case "0":
            console.log(result, "result");
            if (
              result.wsResponse.playType &&
              result.wsResponse.playType == "ADDTURN"
            ) {
              props.openModal(1, result.wsResponse.predictionTurnId);
              break;
            }
            // setTab("2");
            break;
          default:
            break;
        }
      }
    );
  };

  const fetchHistory = (pageNumber = 1, pageSize = consts.PAGE_SIZE) => {
    setLoading(true);
    props.getPlayHistory(
      {
        isdn: utils.isdn(),
        gameCode: consts.GAME_CODE,
        language: i18n.language,
        predictionSession: props.match.id,
        predictionType: props.match.predictType,
        pageNumber,
        pageSize,
      },
      () => {
        setLoading(false);
      }
    );
  };
  const fetchPrize = (pageNumber = 1, pageSize = consts.PAGE_SIZE) => {
    setLoading(true);
    props.getListPrize(
      {
        isdn: utils.isdn(),
        gameCode: consts.GAME_CODE,
        language: i18n.language,
        predictionSession: props.match.id,
        predictionType: props.match.predictType,
        pageNumber,
        pageSize,
      },
      () => {
        setLoading(false);
        setPagePrize(pageNumber);
      }
    );
  };

  React.useEffect(() => {
    setAcceptModal(props.acceptModal);
  }, [props.acceptModal]);

  React.useEffect(() => {
    fetchPrize();
  }, []);

  React.useEffect(() => {
    // setMenu(
    //   <Menu>
    //     {_.map(props.match.matchOption || [], (e) => (
    //       <Menu.Item onClick={() => setChampion(e)} key={e.keyOption}>
    //         <span>{e.nameOtion || "Draw"}</span>
    //       </Menu.Item>
    //     ))}
    //   </Menu>
    // );
    setOptions(props.match.matchOption);
  }, [props.match]);

  console.log(props.prize, "props.prize");

  return (
    <div className={classes.containerDetail}>
      <Tabs
        activeKey={tab}
        centered
        onChange={(activeKey) => {
          if (activeKey == 2) fetchHistory();
          setTab(activeKey);
        }}
        tabBarStyle={{
          color: PRIMARY_COLOR,
          backgroundColor: "white",
        }}
      >
        <TabPane tab={t("predictDetail")} key="1">
          <div className={classes.container2}>
            <span className={classes.matchDesc}>{props.match.matchDesc}</span>
            <div className={classes.container3}>
              <img src={utils.getImageUrl(props.match.firstTeamImageUrl)} />
              <span>vs</span>
              <img src={utils.getImageUrl(props.match.secondTeamImageUrl)} />
            </div>
            <div className={classes.container4}>
              <span className={classes.countryName}>
                {props.match.firstTeamName}
              </span>
              <span className={classes.time}>
                <span>{utils.formatDateMatch(props.match.matchStartTime)}</span>
                <span>{props.match.matchPlace}</span>
              </span>
              <span className={classes.countryName}>
                {props.match.secondTeamName}
              </span>
            </div>
            <div className={classes.option}>
              <span className={classes.optionLabel}>
                {t("choosePrediction")}
              </span>
              {/*<Dropdown*/}
              {/*  overlay={menu}*/}
              {/*  placement="bottomCenter"*/}
              {/*  className={classes.championDropDown}*/}
              {/*>*/}
              {/*  <Button>*/}
              {/*    {champion.nameOtion} <FontAwesomeIcon icon={faChevronDown} />*/}
              {/*  </Button>*/}
              {/*</Dropdown>*/}
              <Select
                defaultValue={champion ? champion.nameOtion : ""}
                className={classes.championDropDown}
                onChange={(value) =>
                  setChampion(_.find(options, (e) => e.nameOtion == value))
                }
              >
                {_.map(options, (e) => (
                  <Select.Option value={e.nameOtion}>
                    {e.nameOtion}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className={classes.option}>
              <span className={classes.optionLabel}>{t("totalScores")}</span>
              <Input
                width={150}
                min={0}
                size={"middle"}
                placeholder={t("totalScores")}
                value={answer}
                onChange={(e) => {
                  e.preventDefault();
                  let rgx = new RegExp("^[0-9]*$");
                  if (rgx.test(e.target.value) || e.target.value == "")
                    setAnswer(e.target.value);
                }}
              />
            </div>
            <Button
              className={classes.btnAuction}
              onClick={() => {
                setAcceptModal(true);
              }}
            >
              {t("predictBtn")}{" "}
            </Button>
            <span className={classes.listPrize}>{t("listPrize")}</span>
            <div className={classes.table}>
              <div className={classes.tableHead}>
                <span className={classes.colPrize1}>{t("no")}</span>
                <span className={classes.colPrize2}>{t("prize")}</span>
                <span className={classes.colPrize3}>{t("desc")}</span>
                <span className={classes.colPrize4}>{t("value")}</span>
              </div>
              <div className={classes.divider}></div>
              {_.map(props.prize, (e, idx) => (
                <div key={e.prizeId}>
                  <div className={classes.contentTable}>
                    <span className={classes.colPrize1}>{idx + 1}</span>
                    <span className={classes.colPrize2}>{e.prizeName}</span>
                    <span className={classes.colPrize3}>{e.prizeDesc}</span>
                    <span className={classes.colPrize4}>
                      {e.prizeValue}
                      {utils.getUnit(e.prizeType)}
                    </span>
                  </div>
                  <div className={classes.divider}></div>
                </div>
              ))}
              <div className={classes.pagination}>
                <div
                  className={classes.btnPagination}
                  onClick={() => {
                    if (pagePrize > 1) {
                      setLoading(true);
                      fetchPrize(pagePrize - 1);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </div>
                <div className={classes.btnPagination}>{pagePrize}</div>
                <div
                  className={classes.btnPagination}
                  onClick={() => {
                    if (props.prize.length == consts.PAGE_SIZE) {
                      setLoading(true);
                      fetchPrize(pagePrize + 1);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tab={t("historyPredict")} key="2">
          <div className={classes.container5}>
            <div className={classes.table}>
              <div className={classes.tableHead}>
                <span className={classes.col1}>{t("no")}</span>
                <span className={classes.col2}>{t("time")}</span>
                <span className={classes.col3}>{t("predictionTeam")}</span>
                <span className={classes.col4}>{t("totalScores")}</span>
              </div>
              <div className={classes.divider}></div>
              {_.map(props.history || [], (e) => (
                <div key={e.predictTime}>
                  <div className={classes.contentTable}>
                    <span className={classes.col1}>1</span>
                    <span className={classes.col2}>
                      {utils.formatDateMatch(e.predictTime)}
                    </span>
                    <span className={classes.col3}>{e.predictResult}</span>
                    <span className={classes.col4}>{e.answer}</span>
                  </div>
                  <div className={classes.divider}></div>
                </div>
              ))}
            </div>
            {/*<span className={classes.listPrize}>Danh sách giải thưởng</span>*/}
            {/*<div className={classes.table}>*/}
            {/*  <div className={classes.tableHead}>*/}
            {/*    <span className={classes.colPrize1}>STT</span>*/}
            {/*    <span className={classes.colPrize2}>Giải</span>*/}
            {/*    <span className={classes.colPrize3}>SL</span>*/}
            {/*    <span className={classes.colPrize4}>Phần thưởng</span>*/}
            {/*    <span className={classes.colPrize5}>Giá trị($)</span>*/}
            {/*  </div>*/}
            {/*  <div className={classes.divider}></div>*/}
            {/*  <div className={classes.contentTable}>*/}
            {/*    <span className={classes.colPrize1}>1</span>*/}
            {/*    <span className={classes.colPrize2}>Nhất</span>*/}
            {/*    <span className={classes.colPrize3}>1</span>*/}
            {/*    <span className={classes.colPrize4}>Phần thưởng 1</span>*/}
            {/*    <span className={classes.colPrize5}>250.00</span>*/}
            {/*  </div>*/}
            {/*  <div className={classes.divider}></div>*/}
            {/*  <div className={classes.contentTable}>*/}
            {/*    <span className={classes.colPrize1}>1</span>*/}
            {/*    <span className={classes.colPrize2}>Nhất</span>*/}
            {/*    <span className={classes.colPrize3}>1</span>*/}
            {/*    <span className={classes.colPrize4}>Phần thưởng 1</span>*/}
            {/*    <span className={classes.colPrize5}>250.00</span>*/}
            {/*  </div>*/}
            {/*  <div className={classes.divider}></div>*/}
            {/*  <div className={classes.contentTable}>*/}
            {/*    <span className={classes.colPrize1}>1</span>*/}
            {/*    <span className={classes.colPrize2}>Nhất</span>*/}
            {/*    <span className={classes.colPrize3}>1</span>*/}
            {/*    <span className={classes.colPrize4}>Phần thưởng 1</span>*/}
            {/*    <span className={classes.colPrize5}>250.00</span>*/}
            {/*  </div>*/}
            {/*  <div className={classes.divider}></div>*/}
            {/*  <div className={classes.contentTable}>*/}
            {/*    <span className={classes.colPrize1}>1</span>*/}
            {/*    <span className={classes.colPrize2}>Nhất</span>*/}
            {/*    <span className={classes.colPrize3}>1</span>*/}
            {/*    <span className={classes.colPrize4}>Phần thưởng 1</span>*/}
            {/*    <span className={classes.colPrize5}>250.00</span>*/}
            {/*  </div>*/}
            {/*  <div className={classes.divider}></div>*/}
            {/*  <div className={classes.contentTable}>*/}
            {/*    <span className={classes.colPrize1}>1</span>*/}
            {/*    <span className={classes.colPrize2}>Nhất</span>*/}
            {/*    <span className={classes.colPrize3}>1</span>*/}
            {/*    <span className={classes.colPrize4}>Phần thưởng 1</span>*/}
            {/*    <span className={classes.colPrize5}>250.00</span>*/}
            {/*  </div>*/}
            {/*  <div className={classes.divider}></div>*/}
            {/*  <div className={classes.contentTable}>*/}
            {/*    <span className={classes.colPrize1}>1</span>*/}
            {/*    <span className={classes.colPrize2}>Nhất</span>*/}
            {/*    <span className={classes.colPrize3}>1</span>*/}
            {/*    <span className={classes.colPrize4}>Phần thưởng 1</span>*/}
            {/*    <span className={classes.colPrize5}>250.00</span>*/}
            {/*  </div>*/}
            {/*  <div className={classes.divider}></div>*/}
            {/*  <div className={classes.contentTable}>*/}
            {/*    <span className={classes.colPrize1}>1</span>*/}
            {/*    <span className={classes.colPrize2}>Nhất</span>*/}
            {/*    <span className={classes.colPrize3}>1</span>*/}
            {/*    <span className={classes.colPrize4}>Phần thưởng 1</span>*/}
            {/*    <span className={classes.colPrize5}>250.00</span>*/}
            {/*  </div>*/}
            {/*  <div className={classes.divider}></div>*/}
            {/*  <div className={classes.contentTable}>*/}
            {/*    <span className={classes.colPrize1}>1</span>*/}
            {/*    <span className={classes.colPrize2}>Nhất</span>*/}
            {/*    <span className={classes.colPrize3}>1</span>*/}
            {/*    <span className={classes.colPrize4}>Phần thưởng 1</span>*/}
            {/*    <span className={classes.colPrize5}>250.00</span>*/}
            {/*  </div>*/}
            {/*  <div className={classes.divider}></div>*/}
            {/*  <div className={classes.contentTable}>*/}
            {/*    <span className={classes.colPrize1}>1</span>*/}
            {/*    <span className={classes.colPrize2}>Nhất</span>*/}
            {/*    <span className={classes.colPrize3}>1</span>*/}
            {/*    <span className={classes.colPrize4}>Phần thưởng 1</span>*/}
            {/*    <span className={classes.colPrize5}>250.00</span>*/}
            {/*  </div>*/}
            {/*  <div className={classes.divider}></div>*/}
            {/*  <div className={classes.contentTable}>*/}
            {/*    <span className={classes.colPrize1}>1</span>*/}
            {/*    <span className={classes.colPrize2}>Nhất</span>*/}
            {/*    <span className={classes.colPrize3}>1</span>*/}
            {/*    <span className={classes.colPrize4}>Phần thưởng 1</span>*/}
            {/*    <span className={classes.colPrize5}>250.00</span>*/}
            {/*  </div>*/}
            {/*  <div className={classes.divider}></div>*/}
            {/*  <div className={classes.contentTable}>*/}
            {/*    <span className={classes.colPrize1}>1</span>*/}
            {/*    <span className={classes.colPrize2}>Nhất</span>*/}
            {/*    <span className={classes.colPrize3}>1</span>*/}
            {/*    <span className={classes.colPrize4}>Phần thưởng 1</span>*/}
            {/*    <span className={classes.colPrize5}>250.00</span>*/}
            {/*  </div>*/}
            {/*  <div className={classes.divider}></div>*/}
            {/*</div>*/}
          </div>
        </TabPane>
      </Tabs>
      <AcceptModal
        visible={acceptModal}
        validateTurn={props.validateTurn}
        predictionType={props.match.predictType}
        handleOk={() => {
          setAcceptModal(false);
          predict();
        }}
        handleCancel={() => {
          setAcceptModal(false);
        }}
      />
      {loading ? <Loading /> : <div />}
    </div>
  );
}

export default PredictDetail;
