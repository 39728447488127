import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import rf from "../../requests/RequestFactory";
import _ from "lodash";
import actions from "../actions/result";
import {
  GET_PREDICT_RESULT,
  GET_PREDICT_RESULT_DETAIL,
  GET_LIST_PRIZE_RESULT,
} from "../actions/result/action_types";

function* getResult(action) {
  console.log("=== getResult ===", action);
  try {
    const { data, status } = yield call(
      (params) => rf.getRequest("ResultRequest").getResult(params),
      action.params
    );
    if (_.isFunction(action.callback)) action.callback();

    if (!status) {
      yield put(actions.getPredictResultFailed(new Error("Error Step 1")));
      return;
    }
    yield put(
      actions.getPredictResultSucceed(
        data.result.wsResponse,
        action.params.predictionType
      )
    );
  } catch (err) {
    yield put(actions.getPredictResultFailed(err));
  }
}

function* getResultDetail(action) {
  console.log("=== getResultDetail ===", action);
  try {
    const { data, status } = yield call(
      (params) => rf.getRequest("ResultRequest").getResultDetail(params),
      action.params
    );
    if (_.isFunction(action.callback)) action.callback();

    if (!status) {
      yield put(
        actions.getPredictResultDetailFailed(new Error("Error Step 1"))
      );
      return;
    }
    yield put(
      actions.getPredictResultDetailSucceed(
        data.result.wsResponse,
        action.params.predictionType
      )
    );
  } catch (err) {
    yield put(actions.getPredictResultDetailFailed(err));
  }
}

function* getListPrizeResult(action) {
  console.log("=== getListPrizeResult ===", action);
  try {
    const { data, status } = yield call(
      (params) => rf.getRequest("ResultRequest").getListPrizeResult(params),
      action.params
    );
    if (_.isFunction(action.callback)) action.callback();

    if (!status) {
      yield put(actions.getListPrizeResultFailed(new Error("Error Step 1")));
      return;
    }
    yield put(actions.getListPrizeResultSucceed(data.result.wsResponse));
  } catch (err) {
    yield put(actions.getListPrizeResultFailed(err));
    if (_.isFunction(action.callback)) action.callback();
  }
}

function* watchAllResult() {
  yield takeLatest(GET_PREDICT_RESULT, getResult);
  yield takeLatest(GET_PREDICT_RESULT_DETAIL, getResultDetail);
  yield takeLatest(GET_LIST_PRIZE_RESULT, getListPrizeResult);
}

export default function* rootSaga() {
  yield all([fork(watchAllResult)]);
}
