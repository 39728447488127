import { isSafari } from "react-device-detect";
import { PRIMARY_COLOR } from "../../consts";

const predictStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    overflowY: isSafari ? "scroll" : "auto",
    padding: "0 12px 70px 12px",
    "& .ant-tabs-tab-active .ant-tabs-tab-btn": {
      color: PRIMARY_COLOR,
      fontWeight: 600,
    },
    "& .ant-tabs-tab-btn": {
      color: "#262626",
      fontWeight: 600,
    },
    "& .ant-tabs-nav-list": {
      // width: "99%",
    },
    "& .ant-tabs": {
      flex: 1,
      backgroundColor: "white",
      marginBottom: 62,
      maxHeight: `100%`,
      overflowY: isSafari ? "scroll" : "auto",
      "-webkit-overflow-scrolling": "touch",
    },
    "& .ant-tabs-tab": {
      width: "50%",
      justifyContent: "center",
    },
    "& .ant-tabs-ink-bar": {
      // width: "50%",
      backgroundColor: PRIMARY_COLOR,
    },
    "& .ant-tabs-content-holder": {
      overflowY: "auto",
    },
  },
  imageAuction: {
    objectFit: "contain",
    width: "100%",
    // height: "100%",
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 6,
  },
};

export default predictStyles;
