const rulePageStyle = {
  container: {
    // flex: 1,
    padding: 16,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    overflowY: "auto",
  },
  content: {
    overflowY: "auto",
    marginBottom: 62,
    fontSize: 13,
    fontWeight: 500,
    lineHeight: "18px",
    maxWidth: "100%",

    "& pre": {
      whiteSpace: "break-spaces",
    },
  },
};

export default rulePageStyle;
