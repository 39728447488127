import { SET_SUBPAGE } from "../actions/common/action_types";
import {
  GET_LIST_PRIZE_RESULT_SUCCEED,
  GET_PREDICT_RESULT_DETAIL_SUCCEED,
  GET_PREDICT_RESULT_SUCCEED,
} from "../actions/result/action_types";

const defaultParams = {
  subPage: "",
  result: [],
  match: [],
  team: {},
  player: {},
  prize: [],
};

export default (state = defaultParams, action) => {
  switch (action.type) {
    case SET_SUBPAGE:
      return {
        ...state,
        subPage: action.subPage,
      };
    case GET_PREDICT_RESULT_SUCCEED:
      switch (action.predictionType) {
        case 1:
        case "1":
          return {
            ...state,
            result: action.data,
          };
        case 2:
        case "2":
          return {
            ...state,
            team: action.data[0],
          };
        case 3:
        case "3":
          return {
            ...state,
            player: action.data[0],
          };
        default:
          return {
            ...state,
          };
      }
    case GET_PREDICT_RESULT_DETAIL_SUCCEED:
      switch (action.predictionType) {
        case 1:
        case "1":
          return {
            ...state,
            match: action.data,
          };
        case 2:
        case "2":
          return {
            ...state,
            team: action.data[0],
          };
        case 3:
        case "3":
          return {
            ...state,
            player: action.data[0],
          };
        default:
          return {
            ...state,
          };
      }
    case GET_LIST_PRIZE_RESULT_SUCCEED:
      return {
        ...state,
        prize: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};
