export const GET_PREDICT_SESSION = "GET_PREDICT_SESSION";
export const GET_PREDICT_SESSION_SUCCEED = "GET_PREDICT_SESSION_SUCCEED";
export const GET_PREDICT_SESSION_FAILED = "GET_PREDICT_SESSION_FAILED";

export const GET_LIST_TEAM = "GET_LIST_TEAM";
export const GET_LIST_TEAM_SUCCEED = "GET_LIST_TEAM_SUCCEED";
export const GET_LIST_TEAM_FAILED = "GET_LIST_TEAM_FAILED";

export const GET_LIST_PLAYER = "GET_LIST_PLAYER";
export const GET_LIST_PLAYER_SUCCEED = "GET_LIST_PLAYER_SUCCEED";
export const GET_LIST_PLAYER_FAILED = "GET_LIST_PLAYER_FAILED";

export const PREDICT = "PREDICT";
export const PREDICT_SUCCEED = "PREDICT_SUCCEED";
export const PREDICT_FAILED = "PREDICT_FAILED";

export const GET_PLAY_HISTORY = "GET_PLAY_HISTORY";
export const GET_PLAY_HISTORY_SUCCEED = "GET_PLAY_HISTORY_SUCCEED";
export const GET_PLAY_HISTORY_FAILED = "GET_PLAY_HISTORY_FAILED";

export const GOTO_TAB = "GOTO_TAB";
export const GOTO_TAB_SUCCEED = "GOTO_TAB_SUCCEED";
export const GOTO_TAB_FAILED = "GOTO_TAB_FAILED";

export const ADD_TURN = "ADD_TURN";
export const ADD_TURN_SUCCEED = "ADD_TURN_SUCCEED";
export const ADD_TURN_FAILED = "ADD_TURN_FAILED";

export const REGISTER_SERVICE = "REGISTER_SERVICE";
export const REGISTER_SERVICE_SUCCEED = "REGISTER_SERVICE_SUCCEED";
export const REGISTER_SERVICE_FAILED = "REGISTER_SERVICE_FAILED";

export const GET_LIST_PRIZE = "GET_LIST_PRIZE";
export const GET_LIST_PRIZE_SUCCEED = "GET_LIST_PRIZE_SUCCEED";
export const GET_LIST_PRIZE_FAILED = "GET_LIST_PRIZE_FAILED";

export const VALIDATE_TURN = "VALIDATE_TURN";
export const VALIDATE_TURN_SUCCEED = "VALIDATE_TURN_SUCCEED";
export const VALIDATE_TURN_FAILED = "VALIDATE_TURN_FAILED";

export const GET_FOOTBALL_LEAGUE = "GET_FOOTBALL_LEAGUE";
export const GET_FOOTBALL_LEAGUE_SUCCEED = "GET_FOOTBALL_LEAGUE_SUCCEED";
export const GET_FOOTBALL_LEAGUE_FAILED = "GET_FOOTBALL_LEAGUE_FAILED";

export const GET_CLUB = "GET_CLUB";
export const GET_CLUB_SUCCEED = "GET_CLUB_SUCCEED";
export const GET_CLUB_FAILED = "GET_CLUB_FAILED";
