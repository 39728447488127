import { connect } from "react-redux";
import common from "redux/actions/common";
import Home from "../views/Home";
import actionsPredict from "redux/actions/predict";

const mapStateToProps = (state) => ({
  subPage: state.common.subPage,
  leagues: state.predict.leagues,
});

const mapDispatchToProps = (dispatch) => ({
  setSubPage: (subPage) => {
    dispatch(common.setSubPage(subPage));
  },
  gotoTab: (tab) => {
    dispatch(actionsPredict.gotoTab(tab));
  },
  getFootballLeagues: (param, callback) => {
    dispatch(actionsPredict.getFootballLeague(param, callback));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
