import React from "react";
import styles from "assets/jss/help";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs } from "antd";
import { PRIMARY_COLOR } from "../consts";
import Guide from "../components/Guide";
import Rule from "../components/Rule";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);
const { TabPane } = Tabs;

function Help(props) {
  const classes = useStyles();
  const { t } = useTranslation("translation");

  return (
    <div className={classes.container}>
      <Tabs
        defaultActiveKey="1"
        centered
        tabBarStyle={{
          color: PRIMARY_COLOR,
          backgroundColor: "white",
        }}
      >
        <TabPane tab={t("privacy")} key="1">
          <Rule {...props} />
        </TabPane>
        <TabPane tab={t("guide")} key="2">
          <Guide {...props} />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default Help;
