import {
  GET_GUIDE,
  GET_GUIDE_FAILED,
  GET_GUIDE_SUCCEED,
  GET_RULE,
  GET_RULE_FAILED,
  GET_RULE_SUCCEED,
} from "./action_types";

export default {
  getGuide: (params, callback) => ({
    type: GET_GUIDE,
    params,
    callback,
  }),
  getGuideSucceed: (data) => ({
    type: GET_GUIDE_SUCCEED,
    data,
  }),
  getGuideFailed: (err) => ({
    type: GET_GUIDE_FAILED,
    err,
  }),

  getRule: (params, callback) => ({
    type: GET_RULE,
    params,
    callback,
  }),
  getRuleSucceed: (data) => ({
    type: GET_RULE_SUCCEED,
    data,
  }),
  getRuleFailed: (err) => ({
    type: GET_RULE_FAILED,
    err,
  }),
};
