import React from "react";
import styles from "assets/jss/predict";
import { makeStyles } from "@material-ui/core/styles";
import consts, { PRIMARY_COLOR } from "../consts";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import MatchComponent from "../components/Predict/Match";
import ChampionComponent from "../components/Predict/Champion";
import PredictDetail from "../components/Predict/PredictDetail";
import OTPModal from "../components/Modal/OTPModal";
import AddTurnModal from "../components/Modal/AddTurnModal";
import RegisterServiceModal from "../components/Modal/RegisterServiceModal";
import DotLoading from "../components/Loading/DotLoading";
import utils from "../common/utils";

const { TabPane } = Tabs;

const useStyles = makeStyles(styles);

function Predict(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation("translation");
  const { subPage } = props;
  const [tab, setTab] = React.useState("1");
  const [match, setMatch] = React.useState(null);
  const [otpAddTurn, setOtpAddTurn] = React.useState(false);
  const [acceptModal] = React.useState(false);
  const [registerModal, setRegisterModal] = React.useState(false);
  const [addTurnModal, setAddTurnModal] = React.useState(false);
  const [otpRegister, setOtpRegister] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [turnId, setTurnId] = React.useState("");
  const [uuid, setUuid] = React.useState("");
  const [option, setOption] = React.useState(null);

  const registerService = (otp = "0") => {
    props.registerService(
      {
        isdn: utils.isdn(),
        gameCode: consts.GAME_CODE,
        language: i18n.language,
        otpType: "1",
        transId: uuid,
        token: localStorage.getItem("token"),
        otp,
      },
      (resp) => {
        setLoading(false);
        if (resp)
          if (resp.errorCode == 0) {
            setOtpRegister(false);
            // setAcceptModal(true);
            utils.showNotification(t("commonReq"), t("registerSuccess"));
          } else {
            utils.showNotification(
              t("commonReq"),
              resp.message || t("serverError"),
              "warning"
            );
          }
      }
    );
  };

  const sendOtpRegisterService = () => {
    const _uuid = utils.uuidv4();
    setUuid(_uuid);
    props.registerService(
      {
        isdn: utils.isdn(),
        gameCode: consts.GAME_CODE,
        language: i18n.language,
        otpType: "0",
        transId: _uuid,
        token: localStorage.getItem("token"),
      },
      (resp) => {
        setLoading(false);
        if (resp)
          if (resp.errorCode == 0) {
            setOtpRegister(true);
            utils.showNotification(t("commonReq"), resp.message);
          } else {
            utils.showNotification(
              t("commonReq"),
              resp.message || t("otpRegisterFail"),
              "error"
            );
          }
      }
    );
  };

  const addTurn = (otp = "0") => {
    props.onAddTurn(
      {
        isdn: utils.isdn(),
        gameCode: consts.GAME_CODE,
        language: i18n.language,
        otp,
        otpType: "1",
        transId: uuid,
        cate: option.cate,
        amount: option.amount,
        predictionTurnId: turnId,
        token: localStorage.getItem("token"),
      },
      (resp) => {
        setLoading(false);
        if (resp)
          if (resp.errorCode == 0) {
            setOtpAddTurn(false);
            // setAcceptModal(true);
            utils.showNotification(t("commonReq"), resp.message);
            setTab("2");
          } else {
            utils.showNotification(
              t("commonReq"),
              resp.message || t("serverError"),
              "warning"
            );
          }
      }
    );
  };

  const sendOtpAddTurn = (option) => {
    const _uuid = utils.uuidv4();
    setUuid(_uuid);
    props.onAddTurn(
      {
        isdn: utils.isdn(),
        gameCode: consts.GAME_CODE,
        language: i18n.language,
        otpType: "0",
        transId: _uuid,
        cate: option.cate,
        amount: option.amount,
        predictionTurnId: turnId,
        token: localStorage.getItem("token"),
      },
      (resp) => {
        setLoading(false);
        if (resp)
          if (resp.errorCode == 0) {
            setOtpAddTurn(true);
          } else {
            utils.showNotification(
              t("notification"),
              resp.message || t("serverError"),
              "warning"
            );
          }
      }
    );
  };

  const getFootballLeagues = () => {
    setLoading(true);
    props.getFootballLeagues(
      {
        gameCode: "PREDICTION_FOOTBALL",
        language: i18n.language,
        pageNumber: 1,
        pageSize: 100,
      },
      () => {
        setLoading(false);
      }
    );
  };

  React.useEffect(() => {
    getFootballLeagues();
    setTab(props.tab);
    console.log(props.tab);
  }, [props.tab]);

  return (
    <div className={classes.container}>
      {subPage == "" ? (
        <Tabs
          activeKey={tab}
          onChange={(activeKey) => setTab(activeKey)}
          centered
          tabBarStyle={{
            color: PRIMARY_COLOR,
            backgroundColor: "white",
            fontWeight: 600,
          }}
        >
          {/* {_.map(props.leagues, (v, i) => ( */}
          <TabPane tab={localStorage.getItem("leagueName")} key={1}>
            <MatchComponent
              {...props}
              tab={tab}
              leagueId={localStorage.getItem("leagueId")}
              predict={(data) => {
                setMatch(data);
                props.setSubPage("predict_detail");
              }}
            />
          </TabPane>
          <TabPane tab={t("championPredict")} key={2}>
            <ChampionComponent
              {...props}
              tab={tab}
              acceptModal={acceptModal}
              openModal={(index, predictionTurnId = "") => {
                switch (index) {
                  case 1:
                    sendOtpAddTurn();
                    setOtpAddTurn(true);
                    setTurnId(predictionTurnId);
                    break;
                  case 2:
                    setRegisterModal(true);
                    break;
                  default:
                    setRegisterModal(false);
                    setAddTurnModal(false);
                }
              }}
            />
          </TabPane>

          {/* ))} */}
        </Tabs>
      ) : subPage == "predict_detail" ? (
        <PredictDetail
          {...props}
          match={match}
          acceptModal={acceptModal}
          openModal={(index, predictionTurnId = "") => {
            switch (index) {
              case 1:
                sendOtpAddTurn();
                setOtpAddTurn(true);
                setTurnId(predictionTurnId);
                break;
              case 2:
                setRegisterModal(true);
                break;
              default:
                setRegisterModal(false);
                setAddTurnModal(false);
            }
          }}
        />
      ) : (
        <div />
      )}
      {otpAddTurn ? (
        <OTPModal
          handleOk={(otp) => {
            addTurn(otp);
          }}
          visible={true}
          title={t("otpTitleV")}
          handleCancel={() => {
            setOtpAddTurn(false);
          }}
          reSend={sendOtpAddTurn}
        />
      ) : (
        <div />
      )}

      {otpRegister ? (
        <OTPModal
          handleOk={(otp) => {
            console.log(otp, "otp");
            registerService(otp);
          }}
          visible={true}
          title={t("otpTitleV")}
          handleCancel={() => {
            setOtpRegister(false);
          }}
          reSend={sendOtpRegisterService}
        />
      ) : (
        <div />
      )}

      {/* list modal*/}

      <AddTurnModal
        visible={addTurnModal}
        handleOk={(option) => {
          setOption(option);
          setAddTurnModal(false);
          setLoading(true);
          sendOtpAddTurn(option);
        }}
        handleCancel={() => {
          setAddTurnModal(false);
        }}
      />

      <RegisterServiceModal
        visible={registerModal}
        handleOk={() => {
          setRegisterModal(false);
          setLoading(true);
          sendOtpRegisterService();
        }}
        handleCancel={() => {
          setRegisterModal(false);
        }}
      />
      {loading ? <DotLoading /> : <div />}
    </div>
  );
}

export default Predict;
