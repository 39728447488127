import React from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/headerStyle";
import {
  faChevronLeft,
  faSignInAlt,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { PRIMARY_COLOR } from "consts";
import utils from "common/utils";
import uk from "assets/img/GB.png";
import mozambique from "assets/img/mozambique.png";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

const listLanguage = [
  {
    name: "en",
    image: uk,
  },
  {
    name: "pt",
    image: mozambique,
  },
];

function Header(props) {
  const classes = useStyles();
  const { icon, onBackPage } = props;
  const { t, i18n } = useTranslation("translation");
  const isLogin = () =>
    localStorage.getItem("token") && localStorage.getItem("token") != "";

  return (
    <div className={classes.container}>
      <div className={classes.container1}>
        <div className={classes.icon} onClick={onBackPage}>
          {icon ? <FontAwesomeIcon icon={faChevronLeft} size={11} /> : <div />}
        </div>
        <span className={classes.title}>Bola</span>
        <div className={classes.icon}></div>
      </div>
      <div
        style={{
          height: 3,
          border: "0.5px solid white",
          backgroundColor: PRIMARY_COLOR,
        }}
      ></div>
      <div className={classes.container2}>
        <div className={classes.container3}>
          {isLogin() ? (
            <span
              onClick={() => {
                props.logout();
              }}
            >
              {utils.isdn()} <FontAwesomeIcon icon={faSignOutAlt} />{" "}
              {t("logout")}
            </span>
          ) : (
            <span
              onClick={() => {
                props.openLogin();
              }}
            >
              <FontAwesomeIcon icon={faSignInAlt} /> {t("login")}
            </span>
          )}
        </div>
        <div>
          {_.map(listLanguage, (e) => (
            <img
              src={e.image}
              key={e.image}
              className={classes.flags}
              style={{
                backgroundColor:
                  i18n.language == e.name ? "white" : PRIMARY_COLOR,
              }}
              onClick={() => i18n.changeLanguage(e.name)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Header;
