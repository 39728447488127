import React, { useState } from "react";
import { Button, Modal, Radio, Space } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/modal";
import { useTranslation } from "react-i18next";
import { PRIMARY_COLOR } from "consts";
import _ from "lodash";

const useStyles = makeStyles(styles);

function AddTurnModal(props) {
  const classes = useStyles();
  const { visible, handleOk, handleCancel } = props;
  const [loading] = useState(false);
  const { t } = useTranslation("translation");

  const options = [
    {
      cate: "CATE1",
      amount: 100,
    },
    {
      cate: "CATE2",
      amount: 200,
    },
    {
      cate: "CATE5",
      amount: 500,
    },
  ];

  const [option, setOption] = useState(options[2]);
  const onChange = (e) => {
    setOption(_.find(options, (o) => o.cate == e.target.value));
  };

  return (
    <Modal
      title=""
      destroyOnClose={true}
      visible={visible}
      onOk={() => handleOk(option)}
      onCancel={handleCancel}
      width={320}
      closable={false}
      bodyStyle={{ borderRadius: 10 }}
      footer={[
        <div
          style={{ display: "flex", justifyContent: "space-evenly" }}
          key={"addturn"}
        >
          <Button
            key="back"
            onClick={handleCancel}
            style={{
              backgroundColor: "white",
              border: `1.5px solid ${PRIMARY_COLOR}`,
              color: PRIMARY_COLOR,
              lineHeight: "18px",
            }}
            className={classes.btnModal}
          >
            {t("cancelBtn")}
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => handleOk(option)}
            style={{ backgroundColor: PRIMARY_COLOR }}
            className={classes.btnModal}
          >
            {t("addTurnBtn")}
          </Button>
        </div>,
      ]}
    >
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignSelf: "flex-start",
        }}
      >
        <span
          style={{
            fontSize: 13,
            lineHeight: "18px",
            color: "#181725",
            fontWeight: 600,
          }}
        >
          {t("buy-turn-more")}
        </span>
        <Radio.Group onChange={onChange} value={option.cate}>
          <Space direction="vertical" align={"start"}>
            <Radio value={options[0].cate}>1 MT = 1 {t("time")}</Radio>
            <Radio value={options[1].cate}>2 MT = 2 {t("times")}</Radio>
            <Radio value={options[2].cate}>5 MT = 10 {t("times")}</Radio>
          </Space>
        </Radio.Group>
      </div>
    </Modal>
  );
}

export default AddTurnModal;
