import BaseRequest from "./BaseRequest";

export default class HomeRequest extends BaseRequest {
  url = "";
  /**
   * @param {string} params.isdn
   * @returns {Promise<BaseRequest._responseHandler.props.data|undefined>}
   */
  cancelMwinService(params) {
    let data = {
      isWrap: "1",
      wsCode: "wsCancelMwinService",
      wsRequest: params,
    };
    return this.post(this.url, data);
  }
}
