import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import rf from "../../requests/RequestFactory";
import _ from "lodash";
import actions from "redux/actions/help";
import { GET_GUIDE, GET_RULE } from "../actions/help/action_types";

function* getGuide(action) {
  console.log("=== getGuide ===", action);
  try {
    const { data, status } = yield call(
      (params) => rf.getRequest("HelpRequest").getGuide(params),
      action.params
    );
    if (_.isFunction(action.callback)) action.callback();

    if (!status) {
      yield put(actions.getGuideFailed(new Error("Error Step 1")));
      return;
    }
    yield put(actions.getGuideSucceed(data.result.wsResponse.rule || ""));
  } catch (err) {
    yield put(actions.getGuideFailed(err));
  }
}

function* getRule(action) {
  console.log("=== getRule ===", action);
  try {
    const { data, status } = yield call(
      (params) => rf.getRequest("HelpRequest").getRule(params),
      action.params
    );
    if (_.isFunction(action.callback)) action.callback();

    if (!status) {
      yield put(actions.getRuleFailed(new Error("Error Step 1")));
      return;
    }
    yield put(actions.getRuleSucceed(data.result.wsResponse.rule || ""));
  } catch (err) {
    yield put(actions.getRuleFailed(err));
  }
}

function* watchAllHelp() {
  yield takeLatest(GET_GUIDE, getGuide);
  yield takeLatest(GET_RULE, getRule);
}

export default function* rootSaga() {
  yield all([fork(watchAllHelp)]);
}
