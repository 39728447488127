import { isSafari } from "react-device-detect";
import { PRIMARY_COLOR } from "consts";

const predictDetailStyles = {
  containerDetail: {
    display: "flex",
    flexDirection: "column",
    overflowY: isSafari ? "scroll" : "auto",
    "& .ant-tabs-tab-active .ant-tabs-tab-btn": {
      color: PRIMARY_COLOR,
      fontWeight: 400,
    },
    "& .ant-tabs-tab-btn": {
      color: "#262626",
      fontWeight: 400,
    },
    "& .ant-tabs-nav-list": {
      width: "99%",
    },
    "& .ant-tabs": {
      flex: 1,
      backgroundColor: "white",
      maxHeight: `100%`,
      // overflowY: isSafari ? "scroll" : "auto",
      "-webkit-overflow-scrolling": "touch",
    },
    "& .ant-tabs-tab": {
      width: "50%",
      justifyContent: "center",
      padding: "8px 0",
    },
    "& .ant-tabs-ink-bar": {
      width: "50%",
      backgroundColor: PRIMARY_COLOR,
    },
    "& .ant-tabs-content-holder": {
      overflowY: "auto",
    },
  },
  imageAuction: {
    objectFit: "contain",
    width: "100%",
    // height: "100%",
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 6,
  },
  container2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container3: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    "& img": {
      width: 76,
      objectFit: "contain",
      borderRadius: 38,
      boxShadow: "0px 1px 6px rgba(0,0,0,0.5)",
    },
    "& span": {
      width: 100,
      textAlign: "center",
    },
  },
  container4: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
  },
  time: {
    width: 100,
    textAlign: "center",
    fontSize: 10,
    color: PRIMARY_COLOR,
    lineHeight: "22px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  countryName: {
    width: 76,
    overflow: "hidden",
    textAlign: "center",
    fontSize: 16,
    fontWeight: "600",
    lineHeight: "22px",
    letterSpacing: "0.1px",
  },
  option: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 17,
    "& input": {
      width: 150,
    },
  },
  optionLabel: {
    flex: 1,
    fontSize: 13,
    fontWeight: 600,
    lineHeight: "16px",
  },
  championDropDown: {
    width: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& span": {
      textAlign: "start",
    },
  },
  btnAuction: {
    fontSize: 13,
    backgroundColor: PRIMARY_COLOR,
    borderRadius: 5,
    color: "white",
    border: "none",
    padding: "8px 16px 6px 16px",
    height: "min-content",
    width: "min-content",
    alignSelf: "center",
    marginTop: 30,
    "&:hover": {
      backgroundColor: "white",
      border: `1px solid ${PRIMARY_COLOR}`,
      color: PRIMARY_COLOR,
    },
    "&:focus": {
      backgroundColor: "white",
      border: `1px solid ${PRIMARY_COLOR}`,
      color: PRIMARY_COLOR,
    },
    "& span": {
      fontWeight: 600,
      letterSpacing: 0.1,
      textTransform: "uppercase",
      lineHeight: "18px",
    },
  },
  container5: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  tableHead: {
    display: "flex",
    alignItems: "center",
    "& span": {
      fontSize: 13,
      fontWeight: 600,
      fontFamily: "Montserrat",
      lineHeight: "16px",
    },
  },
  divider: {
    width: "100%",
    borderTop: "1px solid #D7D7D7",
    margin: "3px 0",
  },
  col1: {
    width: "10%",
    textAlign: "center",
  },
  col2: {
    width: "25%",
    textAlign: "center",
  },
  col3: {
    width: "30%",
    textAlign: "center",
    wordWrap: "break-all",
  },
  col4: {
    width: "35%",
    textAlign: "center",
  },
  colPrize1: {
    width: "10%",
    textAlign: "center",
  },
  colPrize2: {
    width: "35%",
    textAlign: "center",
  },
  colPrize3: {
    width: "35%",
    textAlign: "center",
  },
  colPrize4: {
    width: "20%",
    textAlign: "center",
  },
  contentTable: {
    display: "flex",
    alignItems: "center",
    padding: "12px 0",
    "& span": {
      fontSize: 13,
      lineHeight: "16px",
    },
  },
  listPrize: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: "16px",
    margin: "20px 0",
  },
  matchDesc: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: "18px",
    color: PRIMARY_COLOR,
    fontFamily: "Montserrat",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 8,
  },
  btnPagination: {
    border: "0.7px solid rgba(0,0,0,0.1)",
    width: 30,
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    marginRight: 8,
    marginTop: 8,
    color: "rgba(0,0,0,0.6)",
    backgroundColor: "white",
    "&:hover": {
      boxShadow: "1px 2px 6px #888888",
    },
    "&:focus": {
      opacity: 0.6,
    },
  },
};

export default predictDetailStyles;
