import React from "react";
import styles from "assets/jss/predict/champion";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Dropdown, Input, Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import consts from "../../consts";
import _ from "lodash";
import Loading from "../Loading/DotLoading";
import utils from "../../common/utils";
import AcceptModal from "../Modal/AcceptModal";

const useStyles = makeStyles(styles);

function ChampionComponent(props) {
  const classes = useStyles();
  const [champion, setChampion] = React.useState(null);
  const { t, i18n } = useTranslation("translation");
  const [loading, setLoading] = React.useState(false);
  const [goals, setGoals] = React.useState(0);
  const [pagePrize, setPagePrize] = React.useState(1);
  const [acceptModal, setAcceptModal] = React.useState(false);

  const predict = () => {
    setLoading(true);
    props.predict(
      {
        isdn: localStorage.getItem("isdn"),
        gameCode: consts.GAME_CODE,
        leagueId: localStorage.getItem("leagueId"),
        language: i18n.language,
        predictionSession: props.sessionChampion.id,
        predictionType: props.sessionChampion.predictionValue,
        predictionValue: champion.clubId,
        answer: parseInt(goals),
        token: localStorage.getItem("token"),
      },
      () => {
        setLoading(false);
      },
      (result) => {
        switch (result.errorCode) {
          case "2":
            props.openModal(2);
            break;
          case "11":
            props.openModal(1);
            break;
          case "0":
            if (
              result.wsResponse.playType &&
              result.wsResponse.playType == "ADDTURN"
            ) {
              props.openModal(1, result.wsResponse.predictionTurnId);
              break;
            }
            break;
          default:
            break;
        }
      }
    );
  };

  // const fetchTeam = (isdn = "", pageNumber = 1, pageSize = 30) => {
  //   setLoading(true);
  //   props.getListTeam(
  //     {
  //       isdn,
  //       gameCode: consts.GAME_CODE,
  //       language: i18n.language,
  //       teamId: "",
  //       teamName: "",
  //       state: "",
  //       pageNumber,
  //       pageSize,
  //     },
  //     () => {
  //       setLoading(false);
  //     }
  //   );
  // };

  const fetchClubs = () => {
    setLoading(true);
    props.getClubs(
      {
        isdn: "",
        gameCode: consts.GAME_CODE,
        language: i18n.language,
        leagueId: localStorage.getItem("leagueId"),
        pageNumber: 1,
        pageSize: 100,
      },
      () => {
        setLoading(false);
      }
    );
  };

  const fetchPrize = (pageNumber = 1, pageSize = consts.PAGE_SIZE) => {
    setLoading(true);
    props.getListPrize(
      {
        isdn: utils.isdn(),
        gameCode: consts.GAME_CODE,
        language: i18n.language,
        predictionSession: "",
        predictionType: 2,
        leagueId: localStorage.getItem("leagueId"),
        pageNumber,
        pageSize,
      },
      () => {
        setLoading(false);
        setPagePrize(pageNumber);
      }
    );
  };

  React.useEffect(() => {
    if (props.tab == 2) {
      setLoading(true);
      props.getPredictSession(
        {
          isdn: "",
          gameCode: consts.GAME_CODE,
          language: i18n.language,
          pageNumber: 1,
          predictionType: 2,
          pageSize: consts.PAGE_SIZE,
        },
        () => {
          setLoading(false);
        }
      );
      fetchClubs();
    }
  }, [props.tab]);

  React.useEffect(() => {
    setAcceptModal(props.acceptModal);
  }, [props.acceptModal]);

  React.useEffect(() => {
    fetchPrize();
  }, []);

  const menu = (
    <Menu style={{ maxHeight: 400, overflowY: "scroll" }}>
      {_.map(props.clubs, (e) => (
        <Menu.Item onClick={() => setChampion(e)}>
          <span>{e.clubName}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className={classes.container}>
      <img
        src={localStorage.getItem("imageUrl")}
        style={{ width: "50%", margin: "auto" }}
      />
      <div className={classes.option}>
        <span className={classes.optionLabel}>{t("theChampion")}</span>
        <Dropdown
          overlay={menu}
          placement="bottomCenter"
          className={classes.championDropDown}
        >
          <Button>
            {champion ? champion.clubName : ""}{" "}
            <FontAwesomeIcon icon={faChevronDown} />
          </Button>
        </Dropdown>
      </div>
      <div className={classes.option}>
        <span className={classes.optionLabel}>{t("totalScores")}</span>
        <Input
          width={150}
          size={"middle"}
          // type={"number"}
          placeholder={t("totalScores")}
          value={goals}
          onChange={(e) => {
            e.preventDefault();
            let rgx = new RegExp("^[0-9]*$");
            if (rgx.test(e.target.value) || e.target.value == "")
              setGoals(e.target.value);
          }}
        />
      </div>
      <Button
        className={classes.btnAuction}
        onClick={() => {
          setAcceptModal(true);
        }}
      >
        {t("predictBtn")}{" "}
      </Button>
      <span className={classes.listPrize}>{t("listPrize")}</span>
      <div className={classes.table}>
        <div className={classes.tableHead}>
          <span className={classes.colPrize1}>{t("no")}</span>
          <span className={classes.colPrize2}>{t("prize")}</span>
          <span className={classes.colPrize3}>{t("desc")}</span>
          <span className={classes.colPrize4}>{t("value")}</span>
        </div>
        <div className={classes.divider}></div>
        {_.map(props.prize, (e, idx) => (
          <div key={e.prizeId}>
            <div className={classes.contentTable}>
              <span className={classes.colPrize1}>{idx + 1}</span>
              <span className={classes.colPrize2}>{e.prizeName}</span>
              <span className={classes.colPrize3}>{e.prizeDesc}</span>
              <span className={classes.colPrize4}>
                {e.prizeValue}
                {utils.getUnit(e.prizeType)}
              </span>
            </div>
            <div className={classes.divider}></div>
          </div>
        ))}
        <div className={classes.pagination}>
          <div
            className={classes.btnPagination}
            onClick={() => {
              if (pagePrize > 1) {
                setLoading(true);
                fetchPrize(pagePrize - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
          <div className={classes.btnPagination}>{pagePrize}</div>
          <div
            className={classes.btnPagination}
            onClick={() => {
              if (props.prize.length == consts.PAGE_SIZE) {
                setLoading(true);
                fetchPrize(pagePrize + 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </div>
      <AcceptModal
        visible={acceptModal}
        predictionType={2}
        validateTurn={props.validateTurn}
        handleOk={() => {
          setAcceptModal(false);
          predict();
        }}
        handleCancel={() => {
          setAcceptModal(false);
        }}
      />
      {loading ? <Loading /> : <div />}
    </div>
  );
}

export default ChampionComponent;
