import { notification } from "antd";
import { DNS_IMAGE } from "consts";

// let timeoutID;
const showNotification = (
  message = "title",
  description = "description",
  type = "success"
) => {
  console.log(message);
  notification[type]({
    // message,
    description,
    duration: 15,
  });
};

const paddingZeros = (num, count = 19) =>
  ("0".repeat(count) + num).substr(-count, count);
const trimRightZero = (num) =>
  `${num}`.split(".").length === 2 ? window._.trimEnd(num, "0") : `${num}`;
const trimDot = (num) => window._.trimEnd(`${num}`, ".");
const trimRightZeroAndDot = (num) => trimDot(trimRightZero(num));

function upperCaseFirst(input) {
  return window._.upperFirst(input);
}

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const convertPrice = (price) => {
  if (typeof price == "string") price = Number.parseInt(price);
  return price.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
}

function ellipsisString(str, num) {
  if (str.length <= num) return str;
  return str.substring(0, num - 3) + "...";
}

function checkPhoneNumber(phone) {
  let rgx = /^[0-9]{9}$/g;
  return rgx.test(phone);
}

function checkFloat(number) {
  let rgx = /[+-]?([0-9]*[.])?[0-9]+/g;
  return rgx.test(number);
}

function checkPrice(number) {
  if (number > 0) return true;
  return false;
}

function getTimeForFlipClock(time) {
  return new Date(time * 1000);
}

function parseDateFromString(input) {
  let arr = input.split(/[- :]/);
  let date = new Date(
    arr[0] - 0,
    arr[1] - 1,
    arr[2] - 0,
    arr[3] - 0,
    arr[4] - 0,
    arr[5] - 0
  );
  return date;
}

function getImageUrl(path) {
  if (!path) return "";
  return path.startsWith("http://") || path.startsWith("https://")
    ? path
    : DNS_IMAGE + path;
}

function isdn() {
  return localStorage.getItem("isdn") && localStorage.getItem("isdn") != ""
    ? localStorage.getItem("isdn")
    : "";
}

function checkLengthDesc(desc) {
  return desc.length > 30 ? false : true;
}

function formatDateMatch(time) {
  return time;
  // let date = new Date(time);
  // function formatNumber(number) {
  //   return number.toLocaleString("en-US", {
  //     minimumIntegerDigits: 2,
  //     useGrouping: false,
  //   });
  // }
  // return `${formatNumber(date.getHours())}:${formatNumber(
  //   date.getMinutes()
  // )} ${formatNumber(date.getDay())}/${formatNumber(
  //   date.getMonth()
  // )}/${date.getFullYear()}`;
}

const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const getUnit = (type) => {
  if (type == "3") {
    return " MB";
  } else {
    return " MT";
  }
};

export default {
  checkPhoneNumber,
  checkFloat,
  checkPrice,
  parseDateFromString,
  showNotification,
  paddingZeros,
  trimRightZero,
  trimRightZeroAndDot,
  upperCaseFirst,
  getBase64,
  convertPrice,
  truncateString,
  ellipsisString,
  getTimeForFlipClock,
  getImageUrl,
  isdn,
  checkLengthDesc,
  formatDateMatch,
  uuidv4,
  getUnit,
};
