import React, { useState } from "react";
import { Button, Modal, Spin } from "antd";
import styles from "assets/jss/modal";
import { PRIMARY_COLOR } from "../../consts";
import { makeStyles } from "@material-ui/core/styles";
import "assets/css/modal_repair.css";
import { useTranslation } from "react-i18next";
import utils from "../../common/utils";

const useStyles = makeStyles(styles);

function AcceptModal(props) {
  const classes = useStyles();
  const { visible, handleOk, handleCancel, predictionType } = props;
  const [loading, setLoading] = useState(false);
  const [turn, setTurn] = useState(0);
  const { t, i18n } = useTranslation("translation");

  React.useEffect(() => {
    setLoading(true);
    props.validateTurn(
      {
        predictionType: predictionType,
        isdn: utils.isdn(),
        language: i18n.language,
      },
      (data) => {
        if (data) {
          setTurn(data);
        }
        setLoading(false);
      }
    );
  }, [props.visible]);

  return (
    <Modal
      title=""
      destroyOnClose={true}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={320}
      closable={false}
      bodyStyle={{ borderRadius: 10 }}
      footer={[
        <div
          style={{ display: "flex", justifyContent: "space-evenly" }}
          key={"acceptModal"}
        >
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            style={{ backgroundColor: PRIMARY_COLOR }}
            className={classes.btnModal}
          >
            {t("acceptBtn")}
          </Button>
          <Button
            key="back"
            onClick={handleCancel}
            style={{
              backgroundColor: "white",
              border: `1.5px solid ${PRIMARY_COLOR}`,
              color: PRIMARY_COLOR,
              lineHeight: "18px",
            }}
            className={classes.btnModal}
          >
            {t("cancelBtn")}
          </Button>
        </div>,
      ]}
    >
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin size="small" />
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <span
            style={{
              fontSize: 13,
              lineHeight: "18px",
              color: "#181725",
              fontWeight: 600,
            }}
          >
            {parseInt(turn) > 0 ? t("predictAccept") : t("predictAccept5MT")}
          </span>
        </div>
      )}
    </Modal>
  );
}

export default AcceptModal;
