import { combineReducers } from "redux";
import common from "./common_reducer";
import auth from "./auth_reducer";
import predict from "./predict_reducer";
import help from "./help_reducer";
import result from "./result_reducer";

const allReducers = combineReducers({
  common,
  auth,
  predict,
  help,
  result,
});

export default allReducers;
