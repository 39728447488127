import {
  SET_SUBPAGE,
  SET_SUBPAGE_FAILED,
  SET_SUBPAGE_SUCCEED,
} from "./action_types";

export default {
  setSubPage: (subPage) => ({
    type: SET_SUBPAGE,
    subPage,
  }),
  setSubPageSucceed: () => ({
    type: SET_SUBPAGE_SUCCEED,
  }),
  setSubPageFailed: (err) => ({
    type: SET_SUBPAGE_FAILED,
    err,
  }),
};
