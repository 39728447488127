import utils from "../common/utils";
import consts from "../consts";
import actions from "redux/actions/auth";
import i18n from "i18n";

export default class BaseRequest {
  version = "";

  prefix() {
    return "";
  }

  async get(url, params = {}) {
    try {
      const response = await window.axios.get(`${this.version}/${url}`, {
        params,
      });
      return this._responseHandler(response);
    } catch (error) {
      this._errorHandler(error);
    }
  }

  async getWithTimeout(url, params = {}, timeout) {
    try {
      const response = await window.axios.get(`${this.version}/${url}`, {
        params,
        timeout,
      });
      return this._responseHandler(response);
    } catch (error) {
      this._errorHandler(error);
    }
  }

  async put(url, data = {}, query = {}) {
    try {
      const response = await window.axios.put(`${this.version}/${url}`, data, {
        params: query,
      });
      return this._responseHandler(response);
    } catch (error) {
      this._errorHandler(error);
    }
  }

  async post(url, data = {}, query = {}) {
    try {
      const response = await window.axios.post(`${this.version}/${url}`, data, {
        params: query,
      });
      return this._responseHandler(response);
    } catch (error) {
      this._errorHandler(error);
    }
  }

  async del(url, params = {}) {
    try {
      const response = await window.axios.delete(
        `${this.version}/${url}`,
        params
      );
      return this._responseHandler(response);
    } catch (error) {
      this._errorHandler(error);
    }
  }

  async _responseHandler(response) {
    const { data } = response;
    if (
      data.errorCode != "S200" ||
      (data.result && data.result.errorCode != 0)
    ) {
      this._errorHandler(data);
      return { data: data, status: false };
    }
    return { data, status: true };
  }

  _errorHandler(err) {
    if (err.errorCode != "S200") {
      utils.showNotification(
        i18n.t("commonReq"),
        err.errorMessage,
        consts.TYPE_ERROR
      );
    } else if (err.result && err.result.errorCode != 0) {
      let code = err.result.errorCode;
      if (code == 10) {
        localStorage.setItem("token", "");
        localStorage.setItem("isdn", "");
        console.log("đăng nhập lại");
        window.$dispatch(actions.openModalLogin());
      }
      utils.showNotification(
        i18n.t("commonReq"),
        err.result.message,
        consts.TYPE_ERROR
      );
    }
  }
}
