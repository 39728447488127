import { all } from "redux-saga/effects";
import watchAllPredict from "./predict_saga";
import watchAllAuth from "./auth_saga";
import watchAllHelp from "./help_saga";
import watchAllResult from "./result_saga";

function* rootSaga() {
  yield all([
    watchAllPredict(),
    watchAllAuth(),
    watchAllHelp(),
    watchAllResult(),
  ]);
}

export default rootSaga;
