import { PRIMARY_COLOR } from "consts";

const formItemStyle = {
  container: {
    display: "flex",
  },
  errorMessage: {
    color: "red",
    fontSize: 10,
    lineHeight: "1.2em",
    height: 18,
    paddingTop: 4,
    paddingBottom: 4,
  },
  labelFormItem: {
    fontSize: 13,
    color: "white",
    marginRight: 6,
    width: 60,
  },
  inputFormItem: {
    fontSize: 13,
    padding: 10,
    borderColor: PRIMARY_COLOR,
    borderRadius: 5,
    textAlign: "center",
  },
};

export default formItemStyle;
